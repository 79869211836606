// External elements
import {
  ActivityLog,
  DeliveryForm,
  EditRegion,
  EditUser,
  Region,
  Transaction,
  User,
} from '@/types';
import api from '@/util/api';

export const fetchUserProfile = async (): Promise<User> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api<User>('/users/me', { method: 'GET' });

    if (!response.data) {
      throw new Error('No user data returned');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAdInfo = async (adId: string): Promise<Transaction> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api<Transaction>(`/transaction/${adId}`, {
      method: 'GET',
    });

    if (!response.data) {
      throw new Error('No transaction data returned');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createTransaction = async (adId: string): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api(`/transaction/payload/${adId}`, {
      method: 'POST',
      body: JSON.stringify({}),
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTransactionStatus = async (
  status: string,
  adId: string
): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api(`/transaction/status/${adId}`, {
      method: 'PUT',
      body: JSON.stringify({ status }),
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reactivateTransaction = async (guid: string): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api(`/transaction/reactivate/${guid}`, {
      method: 'PUT',
      body: JSON.stringify({}),
    });

    if (!response.data) {
      throw Error('Transaction could not be reactivated!');
    } else {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const getAllTransactions = async (
  page: number,
  pageSize: number
): Promise<{ total: number; data: Transaction[] }> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api<{ total: number; data: Transaction[] }>(
      `/transaction?page=${page}&pageSize=${pageSize}`,
      {
        method: 'GET',
      }
    );

    if (!response.data) {
      throw new Error('No transactions data returned');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllUsers = async (
  page: number,
  pageSize: number
): Promise<{ total: number; data: User[] }> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api<{ total: number; data: User[] }>(
      `/admin/users?page=${page}&pageSize=${pageSize}`,
      {
        method: 'GET',
      }
    );

    if (!response.data) {
      throw new Error('No users data returned');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (data: User): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api(`/admin/users/${data.email}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data }),
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (data: User): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api(`/admin/users/${data.email}`, {
      method: 'DELETE',
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (data: EditUser): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api('/admin/users', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data }),
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getActivityLog = async (
  page: number,
  pageSize: number
): Promise<ActivityLog> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api<ActivityLog>(
      `/admin/activity?page=${page}&pageSize=${pageSize}`,
      {
        method: 'GET',
      }
    );

    if (!response.data) {
      throw new Error('No activity log data returned');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllRegions = async (): Promise<Region> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api<Region>('/region');

    if (!response.data) {
      throw new Error('No region data returned');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateRegion = async (
  data: EditRegion,
  name: string
): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api(`/admin/region/${name}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data }),
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTransactionRegion = async (
  data: DeliveryForm,
  guid: string
): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api(`/transaction/addAddress/${guid}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data }),
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRegion = async (data: EditRegion): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api(`/admin/region/${data.name}`, {
      method: 'DELETE',
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createRegion = async (data: EditRegion): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api('/admin/region', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data }),
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};
