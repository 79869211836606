// External elements
import { ReactElement } from 'react';

// CSS
import './Footer.scss';

function Footer(): ReactElement {
  return (
    <footer>
      <div className="footer-inner">
        <div className="company-info">
          Swiftcourt (Private) Limited, 00299322, No. 40 Galle Face Court 2,
          Colombo, 00300, Sri Lanka
        </div>
        <div>
          <a href="https://swiftcourt.com/en/ikman/support">Contact us</a> |{' '}
          <a href="https://swiftcourt.com/en/policies/privacy-policy-ikman">
            Privacy Policy
          </a>{' '}
          |
          <a href="https://swiftcourt.com/en/policies/payment-service-terms-and-conditions-ikman">
            Terms & Conditions
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
