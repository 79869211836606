import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '@/store';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import FacebookLoginButton from '@/components/FacebookLoginButton';
import { useReduxDispatch } from '@/store/hooks';
import { setUser } from '@/store/payment';
import { User } from '@/types';
import { fetchUserProfile } from '@/api';

import './Login.scss';

function Login(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const isAuthenticated = useSelector(
    (state: RootState) => !!state.payment.accessToken
  );

  const location = useLocation();
  const from =
    (location.state as { from: { pathname: string } }).from?.pathname || '/';

  const {
    data: user,
    isError: isErrorUser,
    isLoading: isLoadingUser,
  } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUserProfile,
    enabled: !!isAuthenticated,
  });

  useEffect(() => {
    if (isAuthenticated && !isLoadingUser && !isErrorUser) {
      dispatch(setUser(user as User));
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, from, isLoadingUser, isErrorUser]);

  return (
    <div className="login">
      <div className="content">
        <div className="logo-container">
          <img src="/ikmanlogo.png" className="logo-image" alt="Logo" />
        </div>
        <h2>{t('login:title')}</h2>
        <div className="text">{t('login:text')}</div>

        <FacebookLoginButton buttonText={t('login:buttonText')} />
        <p style={{ fontSize: 10, textAlign: 'center', marginTop: 2 }}>
          {t('login:disclaimer')}
        </p>

        <div className="swiftcourt-container">
          <img
            src="/swiftcourtpowered.png"
            className="poweredby-image"
            alt="Swiftcourt"
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
