// External elements
import { ReactElement } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';

// Custom elements
import { useReduxDispatch } from '@/store/hooks';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import ServiceFeeReceipt from '@/components/ServiceFeeReceipt';
import TransactionReceipt from '@/components/TransactionReceipt';
import { fetchAdInfo } from '@/api';

// CSS
import './PurchaseCompleted.scss';

const PurchaseCompleted = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['adInfo', id],
    queryFn: () => fetchAdInfo(id!),
    enabled: !!id,
  });
  return (
    <div className="purchase-completed">
      {isLoading && <h1>Loading...</h1>}
      {isError && <h1>Error</h1>}

      {!isLoading && !isError && data && (
        <>
          <img src="/step-6/receipt.png" />

          <h1>{t('step6:title')}</h1>

          <div className="text-container">
            <p>{t('step6:firstLine')}</p>
            <p>{t('step6:secondLine')}</p>
            <p>
              <Trans
                i18nKey="step6:thirdLine"
                t={t}
                components={{
                  a: (
                    <Link
                      to="mailto:support@swiftcourt.com"
                      style={{ display: 'inline-block' }}
                    >
                      contact us
                    </Link>
                  ),
                }}
              />
            </p>
          </div>

          <div className="receipts-container">
            <div className="receipt-box">
              <img src="/step-6/file-alt.png" />
              <h4>{t('step6:button1Title')}</h4>

              <PDFDownloadLink
                document={<TransactionReceipt data={data} />}
                fileName={`${id ?? '0'}-transaction-receipt.pdf`}
                style={{ width: '100%' }}
              >
                {({ loading }: { loading: boolean }): ReactElement => (
                  <Button>
                    <Icon name="download" style="fas" />
                    {loading ? 'Loading...' : t('step6:button1Text')}
                  </Button>
                )}
              </PDFDownloadLink>
            </div>
            <div className="receipt-box">
              <img src="/step-6/file-alt.png" />
              <h4>{t('step6:button2Title')}</h4>

              <PDFDownloadLink
                document={<ServiceFeeReceipt data={data} />}
                fileName={`${id ?? '0'}-service-fee-receipt.pdf`}
                style={{ width: '100%' }}
              >
                {({ loading }: { loading: boolean }): ReactElement => (
                  <Button>
                    <Icon name="download" style="fas" />
                    {loading ? 'Loading...' : t('step6:button2Text')}
                  </Button>
                )}
              </PDFDownloadLink>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PurchaseCompleted;
