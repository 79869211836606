// Common
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import PageRouter from '@/PageRouter';

import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

// Font-awesome icons
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import {
  faSortDown,
  faArrowLeft,
  faDownload,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

FontAwesomeLibrary.add(fas, faSortDown, faArrowLeft, faDownload, faClose);

function App(): ReactElement {
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login';

  return (
    <div>
      {!isLoginRoute && <Header />}
      <main>
        <PageRouter />
      </main>
      {!isLoginRoute && <Footer />}
    </div>
  );
}

export default App;
