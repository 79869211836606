// External elements
import { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import i18n from '@/language/i18n';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import { useReduxDispatch } from '@/store/hooks';
import { logout } from '@/store/payment';

// Custom elements
import { fetchUserProfile } from '@/api';

// CSS
import './Header.scss';

function Header(): ReactElement {
  const [lang, setLang] = useState('en');
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();

  const {
    data: user,
    isError: isErrorUser,
    isLoading: isLoadingUser,
  } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUserProfile,
  });

  const handleLanguageChange = (event: SelectChangeEvent<string>): void => {
    const value = event.target.value;
    setLang(value);
    localStorage.setItem('lang', value);
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    const language: string | null = localStorage.getItem('lang');

    if (language) {
      setLang(language);
      i18n.changeLanguage(language);
    }

    const handleStorageChange = (event: StorageEvent): void => {
      if (event.key === 'lang' && event.newValue) {
        setLang(event.newValue);
        i18n.changeLanguage(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <header>
      <div className="logos">
        <Box display="flex" alignItems="center">
          <IconButton onClick={(): any => dispatch(logout())}>
            <Logout />
          </IconButton>
        </Box>

        <img src="/ikman-swiftcourt.png" className="centered-image" />

        <Box className="language-select-box">
          <Select
            labelId="language-switcher"
            id="language-switcher"
            value={lang}
            onChange={handleLanguageChange}
            sx={{
              maxHeight: 38,
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              '& .MuiSelect-select': {
                paddingTop: 1,
                paddingBottom: 1,
                paddingRight: 2,
                paddingLeft: 2,
              },
            }}
          >
            <MenuItem value="en">
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                columnGap={1}
                paddingTop="2px"
              >
                <Typography variant="body2">English</Typography>
              </Box>
            </MenuItem>
            <MenuItem value="si">
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                columnGap={1}
                paddingTop="2px"
              >
                <Typography variant="body2">සිංහල</Typography>
              </Box>
            </MenuItem>
            <MenuItem value="ta">
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                columnGap={1}
                paddingTop="2px"
              >
                <Typography variant="body2">தமிழ்</Typography>
              </Box>
            </MenuItem>
          </Select>
        </Box>
      </div>
      {!isLoadingUser && !isErrorUser && user?.role === 'Admin' && (
        <div className="header-text">
          <Typography variant="body2">
            <Link
              sx={{ textDecoration: 'none' }}
              component="button"
              disabled={location.pathname === '/'}
              onClick={(): void => navigate('/')}
              style={{
                color: location.pathname === '/' ? 'grey' : '#009877',
              }}
            >
              Transactions
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link
              sx={{ textDecoration: 'none' }}
              component="button"
              disabled={location.pathname === '/users'}
              onClick={(): void => navigate('/users')}
              style={{
                color: location.pathname === '/users' ? 'grey' : '#009877',
              }}
            >
              Users
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link
              sx={{ textDecoration: 'none' }}
              component="button"
              disabled={location.pathname === '/regions'}
              onClick={(): void => navigate('/regions')}
              style={{
                color: location.pathname === '/regions' ? 'grey' : '#009877',
              }}
            >
              Regions
            </Link>
          </Typography>
        </div>
      )}
    </header>
  );
}

export default Header;
