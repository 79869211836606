import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Login
import translationsEnLogin from './en/login.json';
import translationsLkLogin from './lk/login.json';
import translationsTaLogin from './ta/login.json';

// Step 1
import translationsEnIntro from './en/intro.json';
import translationLkIntro from './lk/intro.json';
import translationsTaIntro from './ta/intro.json';

// Step 2
import translationEnStep2 from './en/step2.json';
import translationLkStep2 from './lk/step2.json';
import translationTaStep2 from './ta/step2.json';

// Step 4
import translationEnStep4 from './en/step4.json';
import translationLkStep4 from './lk/step4.json';
import translationTaStep4 from './ta/step4.json';

// Step 5
import translationEnStep5 from './en/step5.json';
import translationLkStep5 from './lk/step5.json';
import translationTaStep5 from './ta/step5.json';

// Step 6
import translationEnStep6 from './en/step6.json';
import translationLkStep6 from './lk/step6.json';
import translationTaStep6 from './ta/step6.json';

// Dashboard
import translationEnDashboard from './en/dashboard.json';
import translationLkDashboard from './lk/dashboard.json';
import translationTaDashboard from './ta/dashboard.json';

// Errors
import translationEnErrors from './en/errors.json';
import translationLkErrors from './lk/errors.json';
import translationTaErrors from './ta/errors.json';

// Activity log
import translationEnActivitylog from './en/activitylog.json';
import translationLkActivitylog from './lk/activitylog.json';
import translationTaActivityLog from './ta/activitylog.json';

// Users
import translationEnUsers from './en/users.json';
import translationLkUsers from './lk/users.json';
import translationTaUsers from './ta/users.json';

// Service Fee Receipt
import translationEnServiceFeeReceipt from './en/servicefeereceipt.json';
import translationLkServiceFeeReceipt from './lk/servicefeereceipt.json';
import translationTaServiceFeeReceipt from './ta/servicefeereceipt.json';

// Transaction Receipt
import translationEnTransactionReceipt from './en/transactionreceipt.json';
import translationLkTransactionReceipt from './lk/transactionreceipt.json';
import translationTaTransactionReceipt from './ta/transactionreceipt.json';

// Seller Step 1
import translationEnSellerstep1 from './en/sellerstep1.json';
import translationLkSellerstep1 from './lk/sellerstep1.json';
import translationTaSellerstep1 from './ta/sellerstep1.json';

// Seller Step 2
import translationEnSellerstep2 from './en/sellerstep2.json';
import translationLkSellerstep2 from './lk/sellerstep2.json';
import translationTaSellerstep2 from './ta/sellerstep2.json';

// Seller Step 3
import translationEnSellerstep3 from './en/sellerstep3.json';
import translationLkSellerstep3 from './lk/sellerstep3.json';
import translationTaSellerstep3 from './ta/sellerstep3.json';

// Seller Step 4
import translationEnSellerstep4 from './en/sellerstep4.json';
import translationLkSellerstep4 from './lk/sellerstep4.json';
import translationTaSellerstep4 from './ta/sellerstep4.json';

// Payment Paused
import translationEnPaymentPaused from './en/paymentpaused.json';
import translationLkPaymentPaused from './lk/paymentpaused.json';
import translationTaPaymentPaused from './ta/paymentpaused.json';

// Payment Failed
import translationEnPaymentFailed from './en/paymentfailed.json';
import translationLkPaymentFailed from './lk/paymentfailed.json';
import translationTaPaymentFailed from './ta/paymentfailed.json';

// Payment Expired
import translationEnPaymentExpired from './en/paymentexpired.json';
import translationLkPaymentExpired from './lk/paymentexpired.json';
import translationTaPaymentExpired from './ta/paymentexpired.json';

// Ongoing Purchase
import translationEnOngoing from './en/ongoingpurchase.json';
import translationLkOngoing from './lk/ongoingpurchase.json';
import translationTaOngoing from './ta/ongoingpurchase.json';

// Regions
import regionsEn from './en/regions.json';
import regionsLk from './lk/regions.json';
import regionsTa from './ta/regions.json';

// Delivery address
import deliveryEn from './en/deliveryaddress.json';
import deliveryLk from './lk/deliveryaddress.json';
import deliveryTa from './ta/deliveryaddress.json';

// Processing payment
import processingEn from './en/processingpayment.json';
import processingLk from './lk/processingpayment.json';
import processingTa from './ta/processingpayment.json';

const resources = {
  en: {
    intro: translationsEnIntro,
    login: translationsEnLogin,
    step2: translationEnStep2,
    step4: translationEnStep4,
    step5: translationEnStep5,
    step6: translationEnStep6,
    dashboard: translationEnDashboard,
    errors: translationEnErrors,
    activitylog: translationEnActivitylog,
    users: translationEnUsers,
    servicefeereceipt: translationEnServiceFeeReceipt,
    transactionreceipt: translationEnTransactionReceipt,
    sellerstep1: translationEnSellerstep1,
    sellerstep2: translationEnSellerstep2,
    sellerstep3: translationEnSellerstep3,
    sellerstep4: translationEnSellerstep4,
    paymentpaused: translationEnPaymentPaused,
    paymentfailed: translationEnPaymentFailed,
    paymentexpired: translationEnPaymentExpired,
    ongoingpurchase: translationEnOngoing,
    regions: regionsEn,
    deliveryAddress: deliveryEn,
    processingpayment: processingEn,
  },
  si: {
    intro: translationLkIntro,
    login: translationsLkLogin,
    step2: translationLkStep2,
    step4: translationLkStep4,
    step5: translationLkStep5,
    step6: translationLkStep6,
    dashboard: translationLkDashboard,
    errors: translationLkErrors,
    activitylog: translationLkActivitylog,
    users: translationLkUsers,
    servicefeereceipt: translationLkServiceFeeReceipt,
    transactionreceipt: translationLkTransactionReceipt,
    sellerstep1: translationLkSellerstep1,
    sellerstep2: translationLkSellerstep2,
    sellerstep3: translationLkSellerstep3,
    sellerstep4: translationLkSellerstep4,
    paymentpaused: translationLkPaymentPaused,
    paymentfailed: translationLkPaymentFailed,
    paymentexpired: translationLkPaymentExpired,
    ongoingpurchase: translationLkOngoing,
    regions: regionsLk,
    deliveryAddress: deliveryLk,
    processingpayment: processingLk,
  },
  ta: {
    intro: translationsTaIntro,
    login: translationsTaLogin,
    step2: translationTaStep2,
    step4: translationTaStep4,
    step5: translationTaStep5,
    step6: translationTaStep6,
    dashboard: translationTaDashboard,
    errors: translationTaErrors,
    activitylog: translationTaActivityLog,
    users: translationTaUsers,
    servicefeereceipt: translationTaServiceFeeReceipt,
    transactionreceipt: translationTaTransactionReceipt,
    sellerstep1: translationTaSellerstep1,
    sellerstep2: translationTaSellerstep2,
    sellerstep3: translationTaSellerstep3,
    sellerstep4: translationTaSellerstep4,
    paymentpaused: translationTaPaymentPaused,
    paymentfailed: translationTaPaymentFailed,
    paymentexpired: translationTaPaymentExpired,
    ongoingpurchase: translationTaOngoing,
    regions: regionsTa,
    deliveryAddress: deliveryTa,
    processingpayment: processingTa,
  },
};

// eslint-disable-next-line no-undef
const language = localStorage.getItem('language') || 'en';

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
