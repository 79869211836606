// External elements
import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Custom elements
import Button from './Button';
import { fetchAdInfo, fetchUserProfile } from '@/api';

// CSS
import './PaymentPaused.scss';
import Error from '@/views/Error';

function PaymentPaused(): ReactElement {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: user,
    isError: isErrorUser,
    isLoading: isLoadingUser,
    error: errorUser,
  } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUserProfile,
  });

  const {
    data: dataTransaction,
    isLoading: isLoadingTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useQuery({
    queryKey: ['transaction', id],
    queryFn: () => fetchAdInfo(id!),
    enabled: !!id && !!user,
  });

  const isLoading = isLoadingTransaction || isLoadingUser;
  const isError = isErrorTransaction || isErrorUser;

  if (isErrorTransaction) {
    return <Error message={errorTransaction.message} />;
  }
  if (isErrorUser) {
    return <Error message={errorUser.message} />;
  }
  return (
    <>
      {isLoading && <h1>Loading...</h1>}

      {!isError && !isLoading && dataTransaction && user && (
        <div className="error">
          <img src="/404.png" />

          <h1>{t('paymentpaused:title')}</h1>

          <div>
            <p>{t('paymentpaused:paragraph1')}</p>
            <Trans
              i18nKey="paymentpaused:contact"
              t={t}
              components={{
                a: (
                  <Link to="https://swiftcourt.com/en/private/support">
                    contact us
                  </Link>
                ),
              }}
            />

            {user?.email === dataTransaction.seller.email && (
              <Button
                action={(): void => navigate('/')}
                primary={false}
                style={{ width: '100%', marginTop: 50 }}
              >
                {t('paymentpaused:button')}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentPaused;
