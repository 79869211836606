// External elements
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// Custom elements
import { useReduxDispatch } from '@/store/hooks';
import { incrementStep } from '@/store/stepper';
import { fetchAdInfo } from '@/api';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

// CSS
import './EnterPurchaseSum.scss';

const EnterPurchaseSum: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const { id } = useParams();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['adInfo', id],
    queryFn: () => fetchAdInfo(id!),
    enabled: !!id,
  });

  return (
    <>
      {isLoading && <h1>Loading...</h1>}
      {isError && <h1>Error</h1>}

      {!isLoading && !isError && data && (
        <div className="enter-purchase-sum">
          <img src="/step-2/img/pay.png" />

          <div>
            <h1>{t('step2:title')}</h1>
            <p>{t('step2:description')}</p>
          </div>

          <div>
            <table>
              <tr>
                <th>{t('step2:seller')}</th>
              </tr>
              <tr>
                <td>{data.seller.fullName}</td>
              </tr>
              <tr>
                <th>{t('step2:item')}</th>
              </tr>
              <tr>
                <td>
                  {!data.brand || !data.model
                    ? data.adTitle
                    : `${data.brand} - ${data.model}`}
                </td>
              </tr>
            </table>
          </div>

          <div>
            <table>
              <tr>
                <th>{t('step2:deliveryaddress')}</th>
              </tr>
              <tr>
                <td>{data.buyer?.fullName}</td>
              </tr>
              <tr>
                <td>
                  <>
                    {data.address?.streetAddress}, {data.address?.postCode},{' '}
                    {data.address?.city}
                  </>
                </td>
              </tr>
            </table>
          </div>

          <div className="table-price">
            <table>
              <tr>
                <th>{t('step2:toBePaid')}</th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>{t('step2:purchasePrice')}</td>
                <td>Rs</td>
                <td>{data.price}</td>
              </tr>
              <tr>
                <td>{t('step2:fee')}</td>
                <td>Rs</td>
                <td>{data.paymentServiceFee || 0}</td>
              </tr>

              <tr className="total-price">
                <td>{t('step2:total')}</td>
                <td>Rs</td>
                <td>{data.sumPrice || 'Unknown'}</td>
              </tr>
            </table>
          </div>

          <Button action={(): any => dispatch(incrementStep())} primary>
            {t('step2:button')}
            <Icon name="arrow-right" style="fas" />
          </Button>
        </div>
      )}
    </>
  );
};

export default EnterPurchaseSum;
