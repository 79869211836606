// External elements
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

// Custom elements
import { createRegion, deleteRegion, getAllRegions, updateRegion } from '@/api';
import { EditRegion } from '@/types';
import Error from './Error';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 700,
  width: '100%',
  bgcolor: 'background.paper',
  borderRadius: 2,
  border: 0,
  boxShadow: 24,
  p: 4,
};

const Regions = (): ReactElement => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selectedRow, setSelectedRow] = useState<EditRegion>({
    name: '',
    price: 0,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['regions'],
    queryFn: getAllRegions,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EditRegion>();

  const updateRegionMutation = useMutation({
    mutationFn: ({ data, name }: { data: EditRegion; name: string }) =>
      updateRegion(data, name),
    onError: (error) => {
      enqueueSnackbar(`${t('users:error')}: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      setOpen(false);
      enqueueSnackbar(t('regions:regionsUpdatedSuccess'), {
        variant: 'success',
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries({
        queryKey: ['regions'],
      });
    },
  });

  const deleteRegionMutation = useMutation({
    mutationFn: deleteRegion,
    onError: (error) => {
      enqueueSnackbar(`${t('regions:error')}: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      setOpen(false);
      enqueueSnackbar(t('regions:regionDeletedSuccess'), {
        variant: 'success',
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries({
        queryKey: ['regions'],
      });
    },
  });

  const createRegionMutation = useMutation({
    mutationFn: createRegion,
    onError: (error) => {
      enqueueSnackbar(`${t('regions:error')}: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      setOpen(false);
      enqueueSnackbar(t('regions:regionCreatedSuccess'), {
        variant: 'success',
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries({
        queryKey: ['regions'],
      });
    },
  });

  const onSubmit: SubmitHandler<EditRegion> = (data) => {
    if (action === 'edit') {
      updateRegionMutation.mutate({ data: data, name: selectedRow.name });
    } else {
      createRegionMutation.mutate(data);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('regions:tableColumn1'),
      width: 300,
    },
    {
      field: 'price',
      headerName: t('regions:tableColumn2'),
      width: 300,
    },
  ];

  useEffect(() => {
    reset(selectedRow);
  }, [selectedRow]);

  return (
    <div>
      {isLoading && <h1>{t('regions:Loading')}...</h1>}
      {isError && <Error message={error.message} />}

      {!isLoading && !isError && data && (
        <>
          <Box display="flex" width="100%" justifyContent="flex-end" mb={2}>
            <Button
              onClick={(): void => {
                setSelectedRow({
                  name: '',
                  price: 0,
                });
                setOpen(true);
                setAction('create');
              }}
            >
              {t('regions:button')}
            </Button>
          </Box>

          <DataGrid
            rows={data.data}
            columns={columns}
            getRowId={(row): string => (row as { name?: string })?.name ?? ''}
            onRowClick={(params: GridRowParams): void => {
              setOpen(true);
              setAction('edit');
              setSelectedRow(params.row as EditRegion);
            }}
            rowCount={data.total}
            loading={isLoading}
          />

          <Modal open={open} onClose={(): void => setOpen(false)}>
            <Box sx={style} display="flex" flexDirection="column" rowGap={2}>
              <Controller
                control={control}
                name="name"
                defaultValue={selectedRow?.name}
                rules={{
                  required: t('regions:nameError'),
                  maxLength: {
                    value: 100,
                    message: t('regions:charLimitExceeded'),
                  },
                }}
                render={({ field: { onChange, value } }): JSX.Element => (
                  <TextField
                    value={value}
                    label={`${t('regions:name')}*`}
                    fullWidth
                    onChange={(e): void => onChange(e.target.value)}
                    size="small"
                  />
                )}
              />
              {errors.name && (
                <Typography color="error" variant="body2">
                  {errors.name.message as string}
                </Typography>
              )}

              <Controller
                control={control}
                name="price"
                defaultValue={selectedRow?.price}
                rules={{
                  required: t('regions:priceError'),
                }}
                render={({ field: { onChange, value } }): JSX.Element => (
                  <TextField
                    value={value}
                    label={`${t('regions:price')}*`}
                    fullWidth
                    onChange={(e): void => onChange(e.target.value)}
                    inputProps={{ type: 'number' }}
                    size="small"
                  />
                )}
              />
              {errors.price && (
                <Typography color="error" variant="body2">
                  {errors.price.message as string}
                </Typography>
              )}

              <Box
                display="flex"
                width="100%"
                justifyContent={
                  action === 'create' ? 'flex-end' : 'space-between'
                }
                mt={10}
              >
                {action === 'edit' && (
                  <Button
                    variant="contained"
                    color="error"
                    disabled={
                      deleteRegionMutation.isPending ||
                      updateRegionMutation.isPending ||
                      createRegionMutation.isPending
                    }
                    onClick={(): void =>
                      deleteRegionMutation.mutate(selectedRow)
                    }
                  >
                    {deleteRegionMutation.isPending
                      ? `${t('regions:deletingAction')}...`
                      : t('regions:deleteAction')}
                  </Button>
                )}

                <Box display="flex" columnGap={1}>
                  <Button
                    variant="outlined"
                    color="error"
                    disabled={
                      deleteRegionMutation.isPending ||
                      updateRegionMutation.isPending ||
                      createRegionMutation.isPending
                    }
                    onClick={(): void => setOpen(false)}
                  >
                    {t('regions:cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    disabled={
                      deleteRegionMutation.isPending ||
                      updateRegionMutation.isPending ||
                      createRegionMutation.isPending
                    }
                    onClick={handleSubmit(onSubmit)}
                  >
                    {updateRegionMutation.isPending
                      ? `${t('regions:updatingAction')}`
                      : t('regions:updateAction')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Regions;
