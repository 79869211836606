// External elements
import { ReactElement, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

// Custom elements
import Stepper from '@/components/Stepper';
import HowItWorks from '../components/HowItWorks';
import EnterPurchaseSum from '@/components/EnterPurchaseSum';
import WaitForShipment from '@/components/WaitForShipment';
import ReceiveProduct from '@/components/ReceiveProduct';
import PurchaseCompleted from '@/components/PurchaseCompleted';
import WaitingForBuyer from '@/components/WaitingForBuyer';
import ShipTheItem from '@/components/ShipTheItem';
import WaitingForReceival from '@/components/WaitingForReceival';
import PurchaseCompletedSeller from '@/components/PurchaseCompletedSeller';
import PaymentExpired from '@/components/PaymentExpired';
import PaymentPaused from '@/components/PaymentPaused';
import PaymentFailed from '@/components/PaymentFailed';
import OngoingPurchase from '@/components/OngoingPurchase';
import Error from '@/views/Error';
import DirectPay from '@/components/DirectPay';
import { fetchAdInfo, fetchUserProfile } from '@/api';
import { useReduxDispatch } from '@/store/hooks';
import { setCurrentStep, setGuid } from '@/store/stepper';
import DeliveryAddress from '@/components/DeliveryAddress';
import i18n from '@/language/i18n';
import ProcessingPayment from '@/components/ProcessingPayment';

export enum TransactionStatus {
  Unknown = 'unknown',
  New = 'new',
  Created = 'created',
  PaymentSuccess = 'payment-success',
  AwaitingShipment = 'awaiting-shipment',
  Shipped = 'shipped',
  Received = 'received',
  Finalized = 'finalized',
  Completed = 'completed',
  PaymentFailed = 'payment-failed',
  Failed = 'failed',
  Expired = 'expired',
  InitializedPayment = 'initialized-payment',
  BuyerProblem = 'buyer-problem',
  Aborted = 'aborted',
  Blocked = 'blocked',
  ProcessingPayment = 'processing-payment',
}

const Buyer = (): ReactElement => {
  const { id } = useParams();
  const dispatch = useReduxDispatch();
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paymentExpired, setPaymentExpired] = useState(false);
  const [buyerProblem, setBuyerProblem] = useState(false);
  const [aborted, setAborted] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);

  const {
    data: user,
    isError: isErrorUser,
    isLoading: isLoadingUser,
    error: errorUser,
  } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUserProfile,
  });

  const {
    data: dataTransaction,
    isLoading: isLoadingTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useQuery({
    queryKey: ['transaction', id],
    queryFn: () => fetchAdInfo(id!),
    enabled: !!id && !!user,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (id) {
      dispatch(setGuid(id));
    }

    const storedState = localStorage.getItem('persist:root');
    let persistedState: { currentStep: string; guid: string } | null = null;

    if (storedState) {
      persistedState = JSON.parse(storedState) as {
        currentStep: string;
        guid: string;
      };
      if (
        persistedState?.currentStep &&
        persistedState.guid.replace(/^"|"$/g, '') === id &&
        Number(persistedState?.currentStep) < 3
      ) {
        dispatch(setCurrentStep(Number(persistedState.currentStep)));
      } else {
        dispatch(setCurrentStep(0));
      }
    }
  }, []);

  useEffect(() => {
    if (dataTransaction) {
      const status = dataTransaction?.status ?? TransactionStatus.Unknown;

      if (dataTransaction.language) {
        localStorage.setItem('lang', dataTransaction.language);
        i18n.changeLanguage(dataTransaction.language);
      }

      switch (status) {
        case TransactionStatus.Unknown:
          dispatch(setCurrentStep(0));
          break;
        case TransactionStatus.New:
          dispatch(setCurrentStep(0));
          break;
        case TransactionStatus.Created:
          break;
        case TransactionStatus.AwaitingShipment:
          if (user?.email !== dataTransaction.buyer?.email) {
            dispatch(setCurrentStep(1));
          } else {
            dispatch(setCurrentStep(4));
          }
          break;
        case TransactionStatus.InitializedPayment:
          if (user?.email !== dataTransaction.buyer?.email) {
            dispatch(setCurrentStep(0));
          } else {
            dispatch(setCurrentStep(3));
          }
          break;
        case TransactionStatus.ProcessingPayment:
          if (user?.email !== dataTransaction.buyer?.email) {
            dispatch(setCurrentStep(0));
          } else {
            setProcessingPayment(true);
          }
          break;
        case TransactionStatus.Shipped:
          if (user?.email !== dataTransaction.buyer?.email) {
            dispatch(setCurrentStep(2));
          } else {
            dispatch(setCurrentStep(5));
          }
          break;
        case TransactionStatus.Received:
          if (user?.email !== dataTransaction.buyer?.email) {
            dispatch(setCurrentStep(3));
          } else {
            dispatch(setCurrentStep(6));
          }
          break;
        case TransactionStatus.Finalized:
          if (user?.email !== dataTransaction.buyer?.email) {
            dispatch(setCurrentStep(3));
          } else {
            dispatch(setCurrentStep(6));
          }
          break;
        case TransactionStatus.Completed:
          if (user?.email !== dataTransaction.buyer?.email) {
            dispatch(setCurrentStep(3));
          } else {
            dispatch(setCurrentStep(6));
          }
          break;
        case TransactionStatus.Failed:
          setPaymentFailed(true);
          break;
        case TransactionStatus.PaymentFailed:
          setPaymentFailed(true);
          break;
        case TransactionStatus.Expired:
          setPaymentExpired(true);
          break;
        case TransactionStatus.BuyerProblem:
          setBuyerProblem(true);
          break;
        case TransactionStatus.Aborted:
          setAborted(true);
          break;
        case TransactionStatus.Blocked:
          setBlocked(true);
          break;
      }
    }
  }, [dataTransaction]);

  const isLoading = isLoadingTransaction || isLoadingUser;
  const isError = isErrorTransaction || isErrorUser;

  if (paymentFailed) {
    return <PaymentFailed />;
  } else if (paymentExpired) {
    return <PaymentExpired />;
  } else if (buyerProblem) {
    return <PaymentPaused />;
  } else if (aborted) {
    return <PaymentPaused />;
  } else if (processingPayment) {
    return <ProcessingPayment />;
  } else if (blocked) {
    return <OngoingPurchase />;
  } else if (isErrorTransaction) {
    return <Error message={errorTransaction.message} />;
  } else if (isErrorUser) {
    return <Error message={errorUser.message} />;
  } else {
    return (
      <div>
        {isLoading && <h1>Loading...</h1>}

        {!isLoading &&
          !isError &&
          user?.email !== dataTransaction?.buyer?.email &&
          user?.email !== dataTransaction?.seller?.email && <OngoingPurchase />}

        {!isLoading &&
          !isError &&
          user?.email === dataTransaction?.seller.email && (
            <Stepper>
              <Stepper.Steps>
                <Stepper.Step id="seller:neutral" name="Seller: waiting">
                  <WaitingForBuyer />
                </Stepper.Step>
                <Stepper.Step id="seller:first" name="Seller: payment success">
                  <ShipTheItem />
                </Stepper.Step>
                <Stepper.Step id="seller:second" name="Seller: item shipped">
                  <WaitingForReceival />
                </Stepper.Step>
                <Stepper.Step id="seller:third" name="Seller: successful">
                  <PurchaseCompletedSeller />
                </Stepper.Step>
              </Stepper.Steps>
            </Stepper>
          )}

        {!isLoading &&
          !isError &&
          user?.email === dataTransaction?.buyer?.email && (
            <Stepper>
              <Stepper.Steps>
                <Stepper.Step id="buyer:zero" name="Buyer: How it works">
                  <HowItWorks />
                </Stepper.Step>
                <Stepper.Step
                  id="buyer:first"
                  name="Buyer: Enter delivery address"
                >
                  <DeliveryAddress />
                </Stepper.Step>
                <Stepper.Step
                  id="buyer:second"
                  name="Buyer: Enter purchase sum"
                >
                  <EnterPurchaseSum />
                </Stepper.Step>
                <Stepper.Step id="buyer:third" name="Buyer: send payment">
                  <DirectPay />
                </Stepper.Step>
                <Stepper.Step id="buyer:forth" name="Buyer: wait for shipment">
                  <WaitForShipment />
                </Stepper.Step>
                <Stepper.Step id="buyer:fifth" name="Buyer: receive product">
                  <ReceiveProduct />
                </Stepper.Step>
                <Stepper.Step id="buyer:sixth" name="Buyer: purchase completed">
                  <PurchaseCompleted />
                </Stepper.Step>
              </Stepper.Steps>
            </Stepper>
          )}
      </div>
    );
  }
};

export default Buyer;
