import { ReactElement } from 'react';

function NotFound(): ReactElement {
  return (
    <div>
      <h1>404</h1>
    </div>
  );
}

export default NotFound;
