// External elements
import { ReactElement } from 'react';
import { useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Chip,
  CircularProgress,
  Tooltip,
  Modal,
  Box,
  Typography,
  TextField,
} from '@mui/material';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// Custom elements
import {
  fetchAdInfo,
  fetchUserProfile,
  getAllTransactions,
  updateTransactionStatus,
} from '@/api';
import { Buyer, Seller } from '@/types';
import Error from './Error';
import Button from '@/components/Button';
import moment from 'moment';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 700,
  maxHeight: 500,
  overflow: 'scroll',
  display: 'block',
  width: '100%',
  bgcolor: 'background.paper',
  borderRadius: 2,
  border: 0,
  boxShadow: 24,
  p: 4,
};

const getStatusColor = (status: string): string => {
  switch (status) {
    case 'created':
      return '#8256BA';
    case 'payment-success':
      return '#ffd600';
    case 'awaiting-shipment':
      return '#CF931F';
    case 'shipped':
      return '#28B9B9';
    case 'received':
      return '#2C55BA';
    case 'finalized':
      return '#8AC262';
    case 'completed':
      return '#76ff03';
    case 'expired':
      return '#555555';
    case 'payment-failed':
      return '#b71c1c';
    case 'buyer-problem':
      return '#b71c1c';
    case 'aborted':
      return '#A31D1D';
    case 'failed':
      return '#A31D1D';
    case 'blocked':
      return '#A31D1D';
    default:
      return '#009688';
  }
};
interface RowData {
  status:
    | 'expired'
    | 'aborted'
    | 'payment-failed'
    | 'created'
    | 'unknown'
    | 'awaiting-shipment'
    | 'shipped'
    | 'received'
    | 'finalized'
    | 'completed'
    | 'initialized-payment';
}

const Dashboard = (): ReactElement => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [modalStatus, setModalStatus] = useState<string>('closed');

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    data: transactions,
    isLoading: isLoadingTransactions,
    isError: isErrorTransactions,
    error: errorTransactions,
  } = useQuery({
    queryKey: ['transactions', paginationModel],
    queryFn: () =>
      getAllTransactions(paginationModel.page, paginationModel.pageSize),
    placeholderData: keepPreviousData,
  });

  const {
    data: user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    error: errorUser,
  } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUserProfile,
  });

  const {
    data: dataTransaction,
    isLoading: isLoadingTransaction,
    isError: isErrorTransaction,
    error: errorTransaction,
  } = useQuery({
    queryKey: ['transaction', modalStatus],
    queryFn: () => fetchAdInfo(modalStatus),
    enabled: modalStatus !== 'closed' && !!user,
    refetchOnWindowFocus: true,
  });

  const mutation = useMutation<void, Error, { status: string; guid: string }>({
    mutationKey: ['statusUpdate', modalStatus],
    mutationFn: ({ status, guid }: { status: string; guid: string }) =>
      updateTransactionStatus(status, guid),
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully updated status!', {
        variant: 'success',
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries({
        queryKey: ['transactions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['transaction', modalStatus],
      });
    },
  });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const isLoading = isLoadingTransactions || isLoadingUser;
  const isError = isErrorTransactions || isErrorUser;

  const extendedTransactions = transactions?.data.map((transaction) => ({
    ...transaction,
    user: user,
  }));

  const columns: GridColDef[] = user
    ? [
        {
          field: 'guid',
          headerName: t('dashboard:tableColumn1'),
          width: user.role === 'Admin' ? 100 : 150,
        },
        {
          field: 'buyer',
          headerName: t('dashboard:tableColumn2'),
          width: user.role === 'Admin' ? 180 : 200,
          valueGetter: (row: Buyer) => row?.email || '-',
        },
        ...(user.role === 'Admin'
          ? [
              {
                field: 'seller',
                headerName: t('dashboard:tableColumn3'),
                width: 180,
                valueGetter: (row: Seller) => row?.email || '-',
              },
            ]
          : []),
        {
          field: 'timestamp',
          headerName: t('dashboard:tableColumn7'),
          width: user.role === 'Admin' ? 100 : 150,
          valueGetter: (row: string) => moment(row).format('DD-MM-YYYY'),
        },
        {
          field: 'status',
          headerName: t('dashboard:tableColumn4'),
          width: user.role === 'Admin' ? 140 : 180,
          renderCell: (params) => (
            <Tooltip title={params.value as string} placement="top">
              <Chip
                label={params.value as string}
                style={{
                  backgroundColor: getStatusColor(params.value as string),
                  color: 'white',
                }}
              />
            </Tooltip>
          ),
        },
        {
          field: 'action',
          headerName: t('dashboard:tableColumn6'),
          width: 100,
          renderCell: (): JSX.Element => {
            return (
              <img
                src="/dashboard/edit.png"
                style={{ width: 20, height: 20 }}
              />
            );
          },
        },
      ]
    : [];

  if (isErrorUser) {
    return <Error message={errorUser.message} />;
  } else if (isErrorTransactions) {
    return <Error message={errorTransactions.message} />;
  } else {
    return (
      <div style={{ width: '100%' }}>
        {isLoading && <h1>Loading...</h1>}

        {!isLoading && !isError && transactions && !!user && (
          <DataGrid
            rows={extendedTransactions}
            columns={columns}
            getRowId={(row): string => (row as { guid?: string })?.guid ?? ''}
            onRowClick={({ id }): void => {
              if (id) {
                setModalStatus(id as string);
              }
            }}
            rowCount={transactions.total}
            loading={isLoading}
            pageSizeOptions={[20]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            disableRowSelectionOnClick
          />
        )}

        <Modal
          open={modalStatus !== 'closed'}
          onClose={(): void => setModalStatus('closed')}
          aria-labelledby="Dashboard transaction modal"
          aria-describedby="Shows transactions details in dashboard view"
          sx={{
            '& > .MuiBackdrop-root': {
              backgroundColor: 'rgba(0,0,0,0.2)',
            },
          }}
        >
          <Box sx={style}>
            {isLoadingTransaction && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            )}

            {isErrorTransaction && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body1">
                  {errorTransaction.message}
                </Typography>
              </Box>
            )}

            {!isLoadingTransaction &&
              !isErrorTransaction &&
              user?.role === 'Admin' && (
                <Box display="flex" flexDirection="column" rowGap={1}>
                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:buyerInformation')}
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                  >
                    <TextField
                      value={dataTransaction?.buyer.fullName}
                      label={`${t('dashboard:fullName')}`}
                      defaultValue={dataTransaction?.buyer.fullName}
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                    mt={1}
                  >
                    <TextField
                      value={dataTransaction?.buyer.email}
                      label={`${t('dashboard:email')}`}
                      defaultValue={dataTransaction?.buyer.email}
                      fullWidth
                      size="small"
                      disabled
                    />
                    <TextField
                      value={dataTransaction?.buyer.phoneNumber}
                      label={`${t('dashboard:phoneNumber')}`}
                      defaultValue={dataTransaction?.buyer.phoneNumber}
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                    mt={1}
                  >
                    <TextField
                      value={
                        dataTransaction?.address
                          ? `${dataTransaction?.address?.streetAddress}, ${dataTransaction?.address?.city}, ${dataTransaction?.address?.postCode}`
                          : '-'
                      }
                      label={`${t('dashboard:address')}`}
                      defaultValue={
                        dataTransaction?.address
                          ? `${dataTransaction?.address?.streetAddress}, ${dataTransaction?.address?.city}, ${dataTransaction?.address?.postCode}`
                          : '-'
                      }
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:sellerInformation')}
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                  >
                    <TextField
                      value={dataTransaction?.seller.fullName}
                      label={`${t('dashboard:fullName')}`}
                      defaultValue={dataTransaction?.seller.fullName}
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                    mt={1}
                  >
                    <TextField
                      value={dataTransaction?.seller.email}
                      label={`${t('dashboard:email')}`}
                      defaultValue={dataTransaction?.seller.email}
                      fullWidth
                      size="small"
                      disabled
                    />
                    <TextField
                      value={dataTransaction?.seller.phoneNumber}
                      label={`${t('dashboard:phoneNumber')}`}
                      defaultValue={dataTransaction?.seller.phoneNumber}
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                    mt={1}
                  >
                    <TextField
                      value={
                        dataTransaction?.seller?.address
                          ? `${dataTransaction?.address?.streetAddress || '-'}`
                          : '-'
                      }
                      label={`${t('dashboard:address')}`}
                      defaultValue={
                        dataTransaction?.seller?.address
                          ? `${dataTransaction?.address?.streetAddress || '-'}`
                          : '-'
                      }
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:item')}
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                  >
                    <TextField
                      value={dataTransaction?.adId}
                      label={`${t('dashboard:adId')}`}
                      defaultValue={dataTransaction?.adId}
                      fullWidth
                      size="small"
                      disabled
                    />
                    <TextField
                      value={
                        !dataTransaction?.brand || !dataTransaction?.model
                          ? dataTransaction?.adTitle
                          : `${dataTransaction?.model}, ${dataTransaction?.brand}`
                      }
                      label={`${t('dashboard:item')}`}
                      defaultValue={
                        !dataTransaction?.brand || !dataTransaction?.model
                          ? dataTransaction?.adTitle
                          : `${dataTransaction?.model}, ${dataTransaction?.brand}`
                      }
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:price')}
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                  >
                    <TextField
                      value={dataTransaction?.price}
                      label={`${t('dashboard:purchasePrice')}`}
                      defaultValue={`${dataTransaction?.price || 0} Rs`}
                      fullWidth
                      size="small"
                      disabled
                    />
                    <TextField
                      value={dataTransaction?.shippingServiceFee || '-'}
                      label={`${t('dashboard:deliveryFee')}`}
                      defaultValue={dataTransaction?.shippingServiceFee || '-'}
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:status')}
                  </Typography>

                  <Tooltip
                    title={dataTransaction?.status}
                    placement="top"
                    sx={{ maxWidth: 'fit-content' }}
                  >
                    <Chip
                      label={dataTransaction?.status as string}
                      style={{
                        backgroundColor: getStatusColor(
                          dataTransaction?.status as string
                        ),
                        color: 'white',
                        maxWidth: 'fit-content',
                      }}
                    />
                  </Tooltip>

                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:action')}
                  </Typography>

                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    columnGap={2}
                  >
                    {dataTransaction?.status === 'awaiting-shipment' && (
                      <Chip
                        label="Change to: Shipped"
                        style={{
                          backgroundColor: '#999999',
                          color: 'white',
                          maxWidth: 'fit-content',
                        }}
                        clickable
                        onClick={(): void =>
                          mutation.mutate({
                            status: 'shipped',
                            guid: modalStatus,
                          })
                        }
                      />
                    )}

                    {(dataTransaction?.status === 'awaiting-shipment' ||
                      dataTransaction?.status === 'shipped') && (
                      <Chip
                        label="Change to: Received"
                        style={{
                          backgroundColor: '#999999',
                          color: 'white',
                          maxWidth: 'fit-content',
                        }}
                        clickable
                        onClick={(): void =>
                          mutation.mutate({
                            status: 'received',
                            guid: modalStatus,
                          })
                        }
                      />
                    )}

                    {(dataTransaction?.status === 'awaiting-shipment' ||
                      dataTransaction?.status === 'received') && (
                      <Chip
                        label="Change to: Finalized"
                        style={{
                          backgroundColor: '#999999',
                          color: 'white',
                          maxWidth: 'fit-content',
                        }}
                        clickable
                        onClick={(): void =>
                          mutation.mutate({
                            status: 'finalized',
                            guid: modalStatus,
                          })
                        }
                      />
                    )}

                    {dataTransaction?.status !== 'awaiting-shipment' &&
                      dataTransaction?.status !== 'shipped' &&
                      dataTransaction?.status !== 'received' && (
                        <Typography variant="body1">
                          {t('dashboard:tableColumnActionUnavailable')}
                        </Typography>
                      )}
                  </Box>

                  <Button
                    primary={true}
                    action={(): void => navigate(`/payment/${modalStatus}`)}
                    style={{ marginTop: 10 }}
                  >
                    {t('dashboard:button')}
                  </Button>
                </Box>
              )}

            {!isLoadingTransaction &&
              !isErrorTransaction &&
              user?.role !== 'Admin' && (
                <Box display="flex" flexDirection="column" rowGap={1}>
                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:buyerInformation')}
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                  >
                    <TextField
                      value={dataTransaction?.buyer.fullName}
                      label={`${t('dashboard:fullName')}`}
                      defaultValue={dataTransaction?.buyer.fullName}
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                    mt={1}
                  >
                    <TextField
                      value={dataTransaction?.buyer.email}
                      label={`${t('dashboard:email')}`}
                      defaultValue={dataTransaction?.buyer.email}
                      fullWidth
                      size="small"
                      disabled
                    />
                    <TextField
                      value={dataTransaction?.buyer.phoneNumber}
                      label={`${t('dashboard:phoneNumber')}`}
                      defaultValue={dataTransaction?.buyer.phoneNumber}
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                    mt={1}
                  >
                    <TextField
                      value={
                        dataTransaction?.address
                          ? `${dataTransaction?.address?.streetAddress}, ${dataTransaction?.address?.city}, ${dataTransaction?.address?.postCode}`
                          : '-'
                      }
                      label={`${t('dashboard:address')}`}
                      defaultValue={
                        dataTransaction?.address
                          ? `${dataTransaction?.address?.streetAddress}, ${dataTransaction?.address?.city}, ${dataTransaction?.address?.postCode}`
                          : '-'
                      }
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:item')}
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                  >
                    <TextField
                      value={dataTransaction?.adId}
                      label={`${t('dashboard:adId')}`}
                      defaultValue={dataTransaction?.adId}
                      fullWidth
                      size="small"
                      disabled
                    />
                    <TextField
                      value={
                        !dataTransaction?.brand || !dataTransaction?.model
                          ? dataTransaction?.adTitle
                          : `${dataTransaction?.model}, ${dataTransaction?.brand}`
                      }
                      label={`${t('dashboard:item')}`}
                      defaultValue={
                        !dataTransaction?.brand || !dataTransaction?.model
                          ? dataTransaction?.adTitle
                          : `${dataTransaction?.model}, ${dataTransaction?.brand}`
                      }
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:price')}
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap={2}
                  >
                    <TextField
                      value={dataTransaction?.price}
                      label={`${t('dashboard:purchasePrice')}`}
                      defaultValue={`${dataTransaction?.price || 0} Rs`}
                      fullWidth
                      size="small"
                      disabled
                    />
                    <TextField
                      value={
                        (user?.email === dataTransaction?.seller.email
                          ? dataTransaction?.shippingServiceFee
                          : dataTransaction?.paymentServiceFee) || '-'
                      }
                      label={`${t('dashboard:deliveryFee')}`}
                      defaultValue={
                        (user?.email === dataTransaction?.seller.email
                          ? dataTransaction?.shippingServiceFee
                          : dataTransaction?.paymentServiceFee) || '-'
                      }
                      fullWidth
                      size="small"
                      disabled
                    />
                  </Box>

                  <Typography variant="subtitle1" fontWeight={800}>
                    {t('dashboard:status')}
                  </Typography>

                  <Tooltip
                    title={dataTransaction?.status}
                    placement="top"
                    sx={{ maxWidth: 'fit-content' }}
                  >
                    <Chip
                      label={dataTransaction?.status as string}
                      style={{
                        backgroundColor: getStatusColor(
                          dataTransaction?.status as string
                        ),
                        color: 'white',
                        maxWidth: 'fit-content',
                      }}
                    />
                  </Tooltip>

                  <Button
                    primary={true}
                    action={(): void => navigate(`/payment/${modalStatus}`)}
                    style={{ marginTop: 10 }}
                  >
                    {t('dashboard:button')}
                  </Button>
                </Box>
              )}
          </Box>
        </Modal>
      </div>
    );
  }
};

export default Dashboard;
