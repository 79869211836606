import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '.';
import { EnrichedStepperChildren } from '@/components/StepperSteps';

export interface StepperState {
  steps: EnrichedStepperChildren[];
  currentStep: number;
  guid: string;
}

const initialState: StepperState = {
  steps: [],
  currentStep: 0,
  guid: '',
};

export const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    reset: () => initialState,
    setSteps: (state, action: { payload: EnrichedStepperChildren[] }) => {
      state.steps = action.payload;
    },
    setCurrentStep: (state, action: { payload: number }) => {
      state.currentStep = action.payload;
    },
    setGuid: (state, action: { payload: string }) => {
      state.guid = action.payload;
    },
    incrementStep: (state) => {
      if (state.currentStep < state.steps.length - 1) {
        state.currentStep += 1;
      }
    },
    decrementStep: (state) => {
      if (state.currentStep > 0) {
        state.currentStep -= 1;
      }
    },
    resetStepper: (state) => {
      state.currentStep = 0;
    },
  },
});

export const {
  reset,
  setSteps,
  incrementStep,
  decrementStep,
  setGuid,
  resetStepper,
  setCurrentStep,
} = stepperSlice.actions;

export const stepperState = (state: RootState): EnrichedStepperChildren[] =>
  state.stepper.steps;

export default stepperSlice.reducer;
