/* eslint-disable */
// External elements
import { useReduxDispatch, useReduxSelector } from '@/store/hooks';
import {
  hideLoader,
  setAccessToken,
  setUiState,
  showLoader,
} from '@/store/payment';
import { UiStateEnum } from '@/views/UiState';
import React, { useEffect } from 'react';

// CSS
import './FacebookLoginButton.scss';

const FacebookLoginButton: React.FC<{ buttonText: string }> = ({
  buttonText,
}) => {
  const dispatch = useReduxDispatch();

  useEffect(() => {
    const initFacebookSDK = (): void => {
      (window as any).fbAsyncInit = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (window as any).FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v19.0',
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (window as any).FB.AppEvents.logPageView();
      };

      (function (d: Document, s: string, id: string): void {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s);
        js.id = id;
        (js as HTMLScriptElement).src =
          'https://connect.facebook.net/en_US/sdk.js';
        if (fjs.parentNode) {
          fjs.parentNode.insertBefore(js, fjs);
        }
      })(document, 'script', 'facebook-jssdk');
    };

    initFacebookSDK();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusChangeCallback = (response: any): void => {
    if (response.status === 'connected') {
      dispatch(setAccessToken(response.authResponse.accessToken));
      dispatch(setUiState(UiStateEnum.BUYER));
    } else {
      const statusElement = document.getElementById('status');
      if (statusElement) {
        statusElement.innerHTML = 'Please log into this app.';
      }
    }
  };

  const checkLoginState = (): void => {
    (window as any).FB.getLoginStatus((response: any): void => {
      statusChangeCallback(response);
    });
  };

  const loginWithFacebook = (): void => {
    (window as any).FB.login(checkLoginState, {
      scope: 'public_profile,email',
      return_scopes: true,
    });
  };

  return (
    <>
      <button className="facebookButton" onClick={loginWithFacebook}>
        <img src="/logo/Facebook_Logo.png" alt="Facebook logo" />
        {buttonText}
      </button>
      <div id="status"></div>
    </>
  );
};

export default FacebookLoginButton;
/* eslint-enable */
