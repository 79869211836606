import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { UiStateEnum } from '@/views/UiState';
import { User } from '@/types';

export interface PaymentState {
  uiState: UiStateEnum;
  showLoader: boolean;
  accessToken: string;
  user?: User;
}

const initialState: PaymentState = {
  uiState: UiStateEnum.BUYER,
  showLoader: false,
  accessToken: localStorage.getItem('loginToken') || '',
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    reset: () => initialState,
    setUiState: (state, action: PayloadAction<UiStateEnum>) => {
      state.uiState = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      sessionStorage.setItem('loginToken', `Bearer ${action.payload}`);
      localStorage.setItem('loginToken', `${action.payload}`);
    },
    logout: (state) => {
      state.accessToken = '';
      sessionStorage.removeItem('loginToken');
      localStorage.removeItem('loginToken');
    },
    showLoader: (state) => {
      state.showLoader = true;
    },
    hideLoader: (state) => {
      state.showLoader = false;
    },
  },
});

export const {
  reset,
  setUiState,
  setUser,
  setAccessToken,
  showLoader,
  hideLoader,
  logout,
} = paymentSlice.actions;

export const uiState = (state: RootState): UiStateEnum => state.payment.uiState;

export const user = (state: RootState): User | undefined => state.payment.user;

export const accessToken = (state: RootState): string =>
  state.payment.accessToken;

export const isAuthenticated = (state: RootState): boolean =>
  !!state.payment.accessToken;

export default paymentSlice.reducer;
