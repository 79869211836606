// External elements
import React, { CSSProperties, ReactElement } from 'react';
import classNames from 'classnames';

// Custom elements
import Loader from './Loader';

// CSS
import './Button.scss';

interface ButtonProps {
  action?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text?: string;
  primary?: boolean;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  style?: CSSProperties;
}

function Button({
  action = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    void event;
  },
  text = '',
  primary = true,
  loading = false,
  disabled = false,
  children,
  style,
}: ButtonProps): ReactElement {
  const className = classNames({
    button: true,
    'button--primary': primary,
    'button--secondary': !primary,
    'button--loading': loading,
    'button--disabled': disabled,
  });

  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    !disabled && action(event);
  };

  return (
    <button className={className} onClick={clickHandler} style={style}>
      {!loading && text}
      {loading && <Loader />}
      {!loading && children}
    </button>
  );
}

export default Button;
