// External elements
import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

// Custom elements
import { fetchUserProfile } from '@/api';
import Error from '@/views/Error';

function AdminRoute({ children }: { children: ReactElement }): ReactElement {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUserProfile,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <Error message={error.message} />;
  }

  if (data?.role !== 'Admin') {
    return <Navigate to="/" />;
  }

  return children;
}

export default AdminRoute;
