// External elements
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// CSS
import './WaitForShipment.scss';
import Button from './Button';

const WaitForShipment = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="wait-for-shipment">
      <img src="/step-4/hourglass.png" />

      <h1>{t('step4:title')}</h1>

      <div className="text-container">
        <p>{t('step4:firstLine')}</p>
        <p>{t('step4:secondLine')}</p>
        <p>{t('step4:thirdLine')}</p>

        <Button
          action={(): void => {
            window.location.href = 'https://ikman.lk/';
          }}
          primary={false}
          style={{ width: '100%', marginTop: 50 }}
        >
          {t('step4:button')}
        </Button>
      </div>
    </div>
  );
};

export default WaitForShipment;
