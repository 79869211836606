import {
  ReactElement,
  ReactNode,
  Children,
  isValidElement,
  useEffect,
} from 'react';

// Custom elements
import { useReduxSelector, useReduxDispatch } from '../store/hooks';
import { setSteps } from '../store/stepper';

// CSS
import './StepperSteps.scss';

interface StepperProps {
  children?: ReactNode;
}

export interface EnrichedStepperChildren {
  name: string;
  id: string;
  children?: ReactNode;
}

export const StepperSteps = ({ children }: StepperProps): ReactElement => {
  const dispatch = useReduxDispatch();
  const { currentStep, steps } = useReduxSelector((state) => state.stepper);

  useEffect(() => {
    const stepperSteps: EnrichedStepperChildren[] = Children.toArray(children)
      .filter((child): child is ReactElement<EnrichedStepperChildren> =>
        isValidElement(child)
      )
      .map((child) => child.props);
    dispatch(setSteps(stepperSteps));
  }, [children, dispatch]);

  return (
    <div>
      {children &&
        Children.map(children, (child) => {
          if (isValidElement(child)) {
            const childProps = child.props as EnrichedStepperChildren;
            return steps.length && childProps?.id === steps[currentStep]?.id
              ? child
              : null;
          }
          return null;
        })}
    </div>
  );
};

export const StepperStep = ({
  children,
}: EnrichedStepperChildren): ReactElement => {
  return <div className="wrapper">{children}</div>;
};
