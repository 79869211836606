import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import paymentReducer from './payment';
import stepperReducer from './stepper';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedStepperReducer = persistReducer(persistConfig, stepperReducer);

export const store = configureStore({
  reducer: {
    payment: paymentReducer,
    stepper: persistedStepperReducer,
  },
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
