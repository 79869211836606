// External elements
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

// Custom elements
import { fetchAdInfo } from '@/api';
import { TransactionStatus } from '@/views/Buyer';

// CSS
import './ProcessingPayment.scss';

const ProcessingPayment = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [paymentArrived, setPaymentArrived] = useState<boolean>(false);

  const { data } = useQuery({
    queryKey: ['adInfo', id],
    queryFn: () => fetchAdInfo(id!),
    enabled: !!id,
    refetchInterval: 2000,
  });

  useEffect(() => {
    if (data) {
      if (data.status !== TransactionStatus.ProcessingPayment) {
        if (data.status === TransactionStatus.AwaitingShipment) {
          setPaymentArrived(true);
        } else {
          window.location.reload();
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (paymentArrived) {
      const timer = setTimeout(() => {
        window.location.reload();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [paymentArrived]);

  return (
    <div className="processing-payment">
      <img src="/step-4/hourglass.png" />

      <h1>
        {!paymentArrived
          ? t('processingpayment:title')
          : t('processingpayment:titleprocessed')}
      </h1>

      <div className="text-container">
        <p>
          {!paymentArrived
            ? t('processingpayment:paragraph1')
            : t('processingpayment:paragraph2')}
        </p>
        {paymentArrived && <p>{t('processingpayment:paragraph3')}</p>}
        {paymentArrived && <p>{t('processingpayment:paragraph4')}</p>}
      </div>
    </div>
  );
};

export default ProcessingPayment;
