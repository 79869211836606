// External elements
import { ReactElement, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

// Custom elements
import { fetchAdInfo, updateTransactionStatus } from '@/api';
import Button from '@/components/Button';
import { TransactionStatus } from '@/views/Buyer';

// CSS
import './ShipTheItem.scss';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShipTheItem: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id } = useParams();

  const [open, setOpen] = useState<boolean | string>(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['adInfo', id],
    queryFn: () => fetchAdInfo(id!),
    enabled: !!id,
  });

  const mutation = useMutation<void, Error, { status: string; guid: string }>({
    mutationKey: ['statusUpdate', id],
    mutationFn: ({ status, guid }: { status: string; guid: string }) =>
      updateTransactionStatus(status, guid),
    onError: (error) => {
      enqueueSnackbar(`${t('sellerstep2:error')}: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t('sellerstep2:statusSuccess'), {
        variant: 'success',
        autoHideDuration: 1000,
      });
      setOpen(false);
      location.reload();
    },
  });

  return (
    <>
      {isLoading && <h1>{t('sellerstep2:loading')}...</h1>}
      {isError && <h1>{t('sellerstep2:error')}</h1>}

      {!isLoading && !isError && data && (
        <div className="ship-the-item">
          <img src="/step-5/box.png" />

          <div>
            <h1>{t('sellerstep2:title')}</h1>
            <p>
              <b>{t('sellerstep2:subtitle')}</b>
            </p>
          </div>

          <div className="table-price">
            <table>
              <tr>
                <th>{t('sellerstep2:item')}</th>
                <th></th>
              </tr>
              <tr>
                <td>
                  {!data.brand || !data.model
                    ? data.adTitle
                    : `${data.brand} - ${data.model}`}
                </td>
                <td></td>
              </tr>

              <tr>
                <th>{t('sellerstep2:buyer')}</th>
                <th></th>
              </tr>

              <tr>
                <td>
                  {data.buyer.fullName}, {data.buyer.phoneNumber}
                </td>
                <td></td>
              </tr>

              <tr>
                <td>
                  {data.address?.streetAddress}, {data.address?.postCode},{' '}
                  {data.address?.city}
                </td>
                <td></td>
              </tr>

              <tr>
                <th>{t('sellerstep2:payment')}</th>
                <th></th>
              </tr>

              <tr>
                <td>{t('sellerstep2:purchasePrice')}</td>
                <td style={{ textAlign: 'right' }}>Rs {data.price}</td>
              </tr>

              <tr>
                <td>{t('sellerstep2:deliveryfee')}</td>
                <td style={{ textAlign: 'right' }}>
                  Rs {data.shippingServiceFee || 0}
                </td>
              </tr>

              <tr className="total-price">
                <td>{t('sellerstep2:totalpaid')}</td>
                <td style={{ textAlign: 'right' }}>
                  Rs {(data?.shippingServiceFee || 0) + data.price}
                </td>
              </tr>
            </table>
          </div>

          <div className="button-note">
            <p>
              <b>{t('sellerstep2:buttonNotice')}</b>
            </p>
          </div>

          <Dialog
            open={!!open}
            onClose={(): void => setOpen(false)}
            TransitionComponent={Transition}
            keepMounted
          >
            <DialogTitle sx={{ textAlign: 'center', position: 'relative' }}>
              <h1>
                {open === 'confirm'
                  ? t('sellerstep2:itemShipped')
                  : t('sellerstep2:declinePayment')}
              </h1>

              <IconButton
                aria-label="close"
                onClick={(): void => setOpen(false)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ px: 4, pt: 2, pb: 4 }}>
                <p>{t('sellerstep2:confirmalText')}</p>
                <p>
                  {open === 'confirm'
                    ? t('sellerstep2:questionShip')
                    : t('sellerstep2:questionDecline')}
                </p>
              </DialogContentText>

              <div className="button-container">
                <Button
                  primary={open === 'confirm'}
                  style={{ width: '100%' }}
                  loading={mutation.isPending}
                  action={(): any => {
                    if (open === 'confirm' && id) {
                      mutation.mutate({
                        status: TransactionStatus.Shipped,
                        guid: id,
                      });
                    } else if (open === 'decline' && id) {
                      mutation.mutate({
                        status: TransactionStatus.Aborted,
                        guid: id,
                      });
                    } else {
                      enqueueSnackbar(t('sellerstep2:errorUpdate'), {
                        variant: 'error',
                        autoHideDuration: 2000,
                      });
                    }
                  }}
                >
                  {open === 'confirm'
                    ? t('sellerstep2:confirm')
                    : t('sellerstep2:decline')}
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <div className="button-container">
            <Button primary action={(): void => setOpen('confirm')}>
              {t('sellerstep2:confirm')}
            </Button>
            <Button
              primary={false}
              action={(): void => setOpen('decline')}
              style={{ backgroundColor: '#B14B41', color: '#FFFFFF' }}
            >
              {t('sellerstep2:decline')}
            </Button>

            <Button
              action={(): void => navigate('/')}
              primary={false}
              style={{ width: '100%', marginTop: 50 }}
            >
              {t('paymentpaused:button')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ShipTheItem;
