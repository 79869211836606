// External elements
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ReactElement, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Custom elements
import { getActivityLog } from '@/api';
import Error from './Error';
import { ActivityLogRow } from '@/types';

const ActivityLog = (): ReactElement => {
  const { t } = useTranslation();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['activity-log', paginationModel],
    queryFn: () =>
      getActivityLog(paginationModel.page, paginationModel.pageSize),
    placeholderData: keepPreviousData,
  });

  const columns: GridColDef[] = [
    {
      field: 'user',
      headerName: t('activitylog:tableColumn2'),
      width: 100,
      renderCell: (params): string => {
        const user = (params.row as ActivityLogRow).user;
        return user ? user.email : t('activitylog:tableMissingUser');
      },
    },
    {
      field: 'name',
      headerName: t('activitylog:tableColumn3'),
      width: 100,
    },
    {
      field: 'oldStatus',
      headerName: t('activitylog:tableColumn4'),
      width: 150,
    },
    {
      field: 'newStatus',
      headerName: t('activitylog:tableColumn5'),
      width: 150,
    },
    {
      field: 'time',
      headerName: t('activitylog:tableColumn6'),
      width: 200,
      renderCell: (params): string => {
        const time = (params.row as ActivityLogRow).time;
        return moment(time).format('YYYY-MM-DD HH:mm:ss');
      },
    },
  ];

  return (
    <div>
      {isLoading && <h1>{t('activitylog:Loading')}...</h1>}
      {isError && <Error message={error.message} />}

      {!isLoading && !isError && data && (
        <DataGrid
          rows={data.data}
          columns={columns}
          getRowId={(row): string => (row as { time?: string })?.time ?? ''}
          rowCount={data.total}
          loading={isLoading}
          pageSizeOptions={[20]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      )}
    </div>
  );
};

export default ActivityLog;
