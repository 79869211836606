// External elements
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ReactElement, useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Custom elements
import { createUser, deleteUser, getAllUsers, updateUser } from '@/api';
import Error from './Error';
import { EditUser, User } from '@/types';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 700,
  width: '100%',
  bgcolor: 'background.paper',
  borderRadius: 2,
  border: 0,
  boxShadow: 24,
  p: 4,
};

const Users = (): ReactElement => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selectedRow, setSelectedRow] = useState<User>({
    _id: '',
    fbId: '',
    ikmanId: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: '',
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EditUser>();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['users', paginationModel],
    queryFn: () => getAllUsers(paginationModel.page, paginationModel.pageSize),
    placeholderData: keepPreviousData,
  });

  const updateUserMutation = useMutation({
    mutationFn: updateUser,
    onError: (error) => {
      enqueueSnackbar(`${t('users:error')}: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      setOpen(false);
      enqueueSnackbar(t('users:userUpdatedSuccess'), {
        variant: 'success',
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });

  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
    onError: (error) => {
      enqueueSnackbar(`${t('users:error')}: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      setOpen(false);
      enqueueSnackbar(t('users:userDeletedSuccess'), {
        variant: 'success',
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });

  const createUserMutation = useMutation({
    mutationFn: createUser,
    onError: (error) => {
      enqueueSnackbar(`${t('users:error')}: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      setOpen(false);
      enqueueSnackbar(t('users:userCreatedSuccess'), {
        variant: 'success',
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });

  const onSubmit: SubmitHandler<EditUser> = (data) => {
    if (action === 'edit') {
      updateUserMutation.mutate({ ...data, _id: selectedRow._id });
    } else {
      createUserMutation.mutate(data);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'ikmanId',
      headerName: t('users:tableColumn6'),
      width: 150,
      valueGetter: (row: string) => row || 'Not available',
    },
    {
      field: 'firstName',
      headerName: t('users:tableColumn2'),
      width: 150,
    },
    { field: 'lastName', headerName: t('users:tableColumn3'), width: 150 },
    { field: 'email', headerName: t('users:tableColumn4'), width: 200 },
    {
      field: 'details',
      headerName: t('users:tableColumn9'),
      width: 100,
      renderCell: (): JSX.Element => {
        return (
          <img src="./dashboard/edit.png" style={{ width: 20, height: 20 }} />
        );
      },
    },
  ];

  useEffect(() => {
    reset(selectedRow);
  }, [selectedRow]);

  return (
    <div>
      {isLoading && <h1>{t('users:loading')}...</h1>}
      {isError && <Error message={error.message} />}

      {!isLoading && !isError && (
        <>
          <Box display="flex" width="100%" justifyContent="flex-end" mb={2}>
            <Button
              onClick={(): void => {
                setSelectedRow({
                  _id: '',
                  fbId: '',
                  ikmanId: undefined,
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  role: '',
                });
                setOpen(true);
                setAction('create');
              }}
            >
              {t('users:button')}
            </Button>
          </Box>

          <DataGrid
            rows={data?.data}
            columns={columns}
            getRowId={(row): string => (row as { email?: string })?.email ?? ''}
            onRowClick={(params: GridRowParams): void => {
              setOpen(true);
              setAction('edit');
              setSelectedRow(params.row as User);
            }}
            rowCount={data?.total}
            loading={isLoading}
            pageSizeOptions={[20]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
          />

          <Modal open={open} onClose={(): void => setOpen(false)}>
            <Box sx={style} display="flex" flexDirection="column" rowGap={2}>
              <Typography variant="h6" fontWeight={800}>
                {t('users:userInformation')}
              </Typography>

              <Box display="flex" justifyContent="space-between" columnGap={2}>
                <Box display="flex" flexDirection="column" width="100%">
                  <Controller
                    control={control}
                    name="firstName"
                    defaultValue={selectedRow?.firstName}
                    rules={{
                      required: t('users:firstNameError'),
                      maxLength: {
                        value: 100,
                        message: t('users:charLimitExceeded'),
                      },
                    }}
                    render={({ field: { onChange, value } }): JSX.Element => (
                      <TextField
                        value={value}
                        label={`${t('users:firstName')}*`}
                        fullWidth
                        onChange={(e): void => onChange(e.target.value)}
                        size="small"
                      />
                    )}
                  />
                  {errors.firstName && (
                    <Typography color="error" variant="body2">
                      {errors.firstName.message as string}
                    </Typography>
                  )}
                </Box>

                <Box display="flex" flexDirection="column" width="100%">
                  <Controller
                    control={control}
                    name="lastName"
                    defaultValue={selectedRow?.lastName}
                    rules={{
                      required: t('users:lastNameError'),
                      maxLength: {
                        value: 100,
                        message: t('users:charLimitExceeded'),
                      },
                    }}
                    render={({ field: { onChange, value } }): JSX.Element => (
                      <TextField
                        value={value}
                        label={`${t('users:lastName')}*`}
                        fullWidth
                        onChange={(e): void => onChange(e.target.value)}
                        size="small"
                      />
                    )}
                  />
                  {errors.lastName && (
                    <Typography color="error" variant="body2">
                      {errors.lastName.message as string}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" columnGap={2}>
                <Box display="flex" flexDirection="column" width="100%">
                  <Controller
                    control={control}
                    name="email"
                    defaultValue={selectedRow?.email}
                    rules={{
                      required: t('users:emailError'),
                      maxLength: {
                        value: 100,
                        message: t('users:charLimitExceeded'),
                      },
                    }}
                    render={({ field: { onChange, value } }): JSX.Element => (
                      <TextField
                        value={value}
                        label={`${t('users:email')}*`}
                        fullWidth
                        disabled={action === 'edit'}
                        onChange={(e): void => onChange(e.target.value)}
                        size="small"
                      />
                    )}
                  />
                  {errors.email && (
                    <Typography color="error" variant="body2">
                      {errors.email.message as string}
                    </Typography>
                  )}
                </Box>

                <Box display="flex" flexDirection="column" width="100%">
                  <Controller
                    control={control}
                    name="phoneNumber"
                    defaultValue={selectedRow?.phoneNumber}
                    rules={{
                      maxLength: {
                        value: 100,
                        message: t('users:charLimitExceeded'),
                      },
                    }}
                    render={({ field: { onChange, value } }): JSX.Element => (
                      <TextField
                        value={value}
                        label={t('users:phoneNumber')}
                        fullWidth
                        onChange={(e): void => onChange(e.target.value)}
                        size="small"
                      />
                    )}
                  />
                  {errors.phoneNumber && (
                    <Typography color="error" variant="body2">
                      {errors.phoneNumber.message as string}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" columnGap={2}>
                <Box display="flex" flexDirection="column" width="100%">
                  <Controller
                    control={control}
                    name="ikmanId"
                    defaultValue={selectedRow?.ikmanId}
                    rules={{
                      maxLength: {
                        value: 100,
                        message: t('users:charLimitExceeded'),
                      },
                    }}
                    render={({ field: { onChange, value } }): JSX.Element => (
                      <TextField
                        value={value}
                        label={t('users:ikmanId')}
                        fullWidth
                        onChange={(e): void => onChange(e.target.value)}
                        size="small"
                      />
                    )}
                  />
                  {errors.ikmanId && (
                    <Typography color="error" variant="body2">
                      {errors.ikmanId.message as string}
                    </Typography>
                  )}
                </Box>

                <Box display="flex" flexDirection="column" width="100%">
                  <Controller
                    control={control}
                    name="fbId"
                    defaultValue={selectedRow?.fbId}
                    rules={{
                      maxLength: {
                        value: 100,
                        message: t('users:charLimitExceeded'),
                      },
                    }}
                    render={({ field: { onChange, value } }): JSX.Element => (
                      <TextField
                        value={value}
                        label={t('users:facebookId')}
                        fullWidth
                        onChange={(e): void => onChange(e.target.value)}
                        size="small"
                      />
                    )}
                  />
                  {errors.fbId && (
                    <Typography color="error" variant="body2">
                      {errors.fbId.message as string}
                    </Typography>
                  )}
                </Box>

                <Box display="flex" flexDirection="column" width="100%">
                  <Controller
                    control={control}
                    name="role"
                    defaultValue={selectedRow?.role}
                    rules={{
                      required: t('users:roleError'),
                      maxLength: {
                        value: 100,
                        message: t('users:charLimitExceeded'),
                      },
                    }}
                    render={({ field: { onChange, value } }): JSX.Element => (
                      <Select
                        label={t('users:role')}
                        value={value}
                        onChange={onChange}
                        defaultValue={selectedRow?.role}
                        size="small"
                      >
                        <MenuItem value="User">{t('users:user')}</MenuItem>
                        <MenuItem value="Admin">{t('users:admin')}</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.role && (
                    <Typography color="error" variant="body2">
                      {errors.role.message as string}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box
                display="flex"
                width="100%"
                mt={8}
                justifyContent={
                  action === 'create' ? 'flex-end' : 'space-between'
                }
              >
                {action === 'edit' && (
                  <Button
                    variant="contained"
                    color="error"
                    disabled={
                      deleteUserMutation.isPending ||
                      updateUserMutation.isPending ||
                      createUserMutation.isPending
                    }
                    onClick={(): void => deleteUserMutation.mutate(selectedRow)}
                  >
                    {deleteUserMutation.isPending
                      ? `${t('users:deletingAction')}...`
                      : t('users:deleteAction')}
                  </Button>
                )}

                <Box display="flex" columnGap={1}>
                  <Button
                    variant="outlined"
                    color="error"
                    disabled={
                      deleteUserMutation.isPending ||
                      updateUserMutation.isPending ||
                      createUserMutation.isPending
                    }
                    onClick={(): void => setOpen(false)}
                  >
                    {t('users:cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    disabled={
                      deleteUserMutation.isPending ||
                      updateUserMutation.isPending ||
                      createUserMutation.isPending
                    }
                    onClick={handleSubmit(onSubmit)}
                  >
                    {updateUserMutation.isPending
                      ? `${t('users:updatingAction')}`
                      : t('users:updateAction')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Users;
