import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './Error.scss';

function Error({ message }: { message: string }): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="error">
      <img src="/404.png" />

      <h1>{t('errors:title')}</h1>

      <p>Error message: {message || t('errors:unknownError')}</p>
    </div>
  );
}

export default Error;
