import React, { ReactElement, useEffect, useState } from 'react';
import { useReduxSelector } from '@/store/hooks';

import BuyerHowItWorks from '@/components/HowItWorks';

export enum UiStateEnum {
  BUYER = 'BUYER',
  LOGIN = 'LOGIN',
}

import Loading from '@/views/Loading';
import Login from './Login';
import Buyer from './Buyer';

function UiState(): ReactElement {
  const defaultView = <></>;
  const [activeView, setActiveView] = useState(defaultView);
  const uiState = useReduxSelector((state) => state.payment.uiState);
  const showLoader = useReduxSelector((state) => state.payment.showLoader);

  const viewMap: Map<UiStateEnum, ReactElement> = new Map([
    [UiStateEnum.BUYER, <Buyer />],
  ]);

  useEffect(() => {
    let view = defaultView;
    if (viewMap.has(uiState)) {
      view = viewMap.get(uiState) || <></>;
    }
    setActiveView(view);
  }, [uiState]);

  return (
    <React.Fragment>{showLoader ? <Loading /> : activeView}</React.Fragment>
  );
}

export default UiState;
