import { store } from '@/store';
import { logout } from '@/store/payment';

const API_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:3000';

interface FetchOptions extends RequestInit {
  headers?: Record<string, string>;
}

interface ApiResponse<T = unknown> {
  data?: T;
  error?: unknown;
  status?: number;
}

const api = async <T = unknown>(
  url: string,
  options: FetchOptions = {}
): Promise<ApiResponse<T>> => {
  const state = store.getState();
  const token =
    state.payment.accessToken || sessionStorage.getItem('loginToken');

  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  try {
    const response = await fetch(`${API_URL}${url}`, {
      ...options,
      headers: {
        ...headers,
        ...options.headers,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        store.dispatch(logout());
      }
      const error = (await response.json()) as unknown;
      return { error };
    }

    const data = (await response.json()) as T;
    return { data };
  } catch (error) {
    return { error: error };
  }
};

export default api;
