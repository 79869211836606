// External elements
import { ReactElement } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';

// Custom elements
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import TransactionReceipt from '@/components/TransactionReceipt';
import { fetchAdInfo } from '@/api';

// CSS
import './PurchaseCompletedSeller.scss';

const PurchaseCompletedSeller = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['adInfo', id],
    queryFn: () => fetchAdInfo(id!),
    enabled: !!id,
  });
  return (
    <div className="purchase-completed-seller">
      {isLoading && <h1>{t('sellerstep4:loading')}...</h1>}
      {isError && <h1>{t('sellerstep4:error')}</h1>}

      {!isLoading && !isError && data && (
        <>
          <img src="/step-6/receipt.png" />

          <h1>{t('sellerstep4:title')}</h1>

          <div className="text-container">
            <p>{t('sellerstep4:subtitle1')}</p>
            <p>
              <Trans
                i18nKey="sellerstep4:subtitle2"
                t={t}
                components={{
                  a: <Link to="mailto:support@swiftcourt.com">contact us</Link>,
                }}
              />
            </p>
          </div>

          <div className="receipts-container">
            <div className="receipt-box">
              <img src="/step-6/file-alt.svg" />
              <h4>{t('step6:button1Title')}</h4>

              <PDFDownloadLink
                document={<TransactionReceipt data={data} isSeller={true} />}
                fileName={`${id ?? '0'}-transaction-receipt.pdf`}
                style={{ width: '100%' }}
              >
                {({ loading }: { loading: boolean }): ReactElement => (
                  <Button>
                    <Icon name="download" style="fas" />
                    {loading
                      ? t('sellerstep4:loading')
                      : t('step6:button1Text')}
                  </Button>
                )}
              </PDFDownloadLink>
            </div>
          </div>

          <Button
            action={(): void => navigate('/')}
            primary={false}
            style={{ width: '100%', marginTop: 50 }}
          >
            {t('paymentpaused:button')}
          </Button>
        </>
      )}
    </div>
  );
};

export default PurchaseCompletedSeller;
