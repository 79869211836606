// External elements
import { ReactElement } from 'react';

// CSS
import './WaitForShipment.scss';
import { useTranslation } from 'react-i18next';

const OngoingPurchase = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="wait-for-shipment">
      <img src="/step-4/hourglass.png" />

      <h1>{t('ongoingpurchase:title')}</h1>

      <div className="text-container">
        <p>{t('ongoingpurchase:paragraph1')}</p>
        <p>{t('ongoingpurchase:paragraph2')}</p>
      </div>
    </div>
  );
};

export default OngoingPurchase;
