// External elements
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Custom elements
import { Transaction } from '@/types';

Font.register({
  family: 'NotoSansSinhala',
  fonts: [
    { src: '/NotoSansSinhala-Regular.ttf' },
    { src: '/NotoSansSinhala-SemiBold.ttf', fontWeight: 'bold' },
  ],
});

Font.register({
  family: 'NotoSansTamil',
  fonts: [
    { src: '/NotoSansTamil-Regular.ttf' },
    { src: '/NotoSansTamil-SemiBold.ttf', fontWeight: 'bold' },
  ],
});

Font.register({
  family: 'NotoSans',
  fonts: [
    { src: '/NotoSans-Regular.ttf' },
    { src: '/NotoSans-SemiBold.ttf', fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    padding: 30,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
  },
  buyerInfo: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    marginTop: 40,
    marginBottom: 80,
  },
  buyerText: {
    fontSize: 10,
  },
  paymentDetails: {
    marginBottom: 20,
  },
  paymentDetailsText: {
    marginBottom: 15,
    fontSize: 10,
  },
  table: {
    display: 'flex',
    width: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '33.33%',
  },
  tableCell: {
    marginBottom: 10,
  },
  footer: {
    marginTop: 20,
    fontSize: 10,
  },
});

const ServiceFeeReceipt = ({ data }: { data: Transaction }): ReactElement => {
  const { t } = useTranslation();

  const setLanguageFont = (lang: string | null): string => {
    switch (lang) {
      case 'si':
        return 'NotoSansSinhala';
      case 'ta':
        return 'NotoSansTamil';
      default:
        return 'NotoSans';
    }
  };

  return (
    <Document>
      <Page
        size="A4"
        style={{
          ...styles.page,
          fontFamily: setLanguageFont(localStorage.getItem('lang')),
        }}
      >
        <View style={styles.section}>
          <Image
            src="/ikman-swiftcourt.png"
            style={{ maxWidth: 225, width: '100%', marginBottom: 40 }}
          />
          <Text style={styles.header}>{t('servicefeereceipt:title')}</Text>
          <Text>
            {t('servicefeereceipt:subtitle')} #{data.guid}
          </Text>
        </View>
        <View style={styles.buyerInfo}>
          <Text style={styles.buyerText}>{t('servicefeereceipt:buyer')}:</Text>
          <Text>
            {t('servicefeereceipt:fullName')}: {data.buyer.fullName}
          </Text>
          <Text>
            {t('servicefeereceipt:email')}: {data.buyer.email}
          </Text>
          <Text>
            {t('servicefeereceipt:address')}: {data.address?.streetAddress},{' '}
            {data.address?.postCode}, {data.address?.city}
          </Text>
        </View>
        <View style={styles.paymentDetails}>
          <Text style={styles.paymentDetailsText}>
            {t('servicefeereceipt:paymentDetails')}
          </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {t('servicefeereceipt:amountPaid')}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {t('servicefeereceipt:datePaid')}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {t('servicefeereceipt:method')}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {data.paymentServiceFee} Rs
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {data.timestamp &&
                    moment(data.timestamp).format('DD.MM.YYYY')}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {t('servicefeereceipt:card')}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: '#f0f0f0',
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 170,
          }}
        >
          <Text>{t('servicefeereceipt:paymentServiceFee')}</Text>
          <Text>{data?.paymentServiceFee} Rs</Text>
        </View>
        <View style={styles.footer}>
          <Text style={{ textAlign: 'center', marginBottom: 20 }}>
            {t('servicefeereceipt:contactUsText')}
          </Text>
          <Text
            style={{
              marginTop: 20,
              borderTop: '2px solid #C5C5C5',
              paddingTop: 20,
              maxWidth: 400,
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            {t('servicefeereceipt:emailNotice')}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ServiceFeeReceipt;
