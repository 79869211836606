import { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';

import AdminRoute from './components/AdminRoute';
import ProtectedRoute from './components/ProtectedRoute';

import Dashboard from './views/Dashboard';
import Login from './views/Login';
import UiState from './views/UiState';
import Users from './views/Users';
import ActivityLog from './views/ActivityLog';
import Regions from './views/Regions';
import NotFound from './views/NotFound';

function PageRouter(): ReactElement {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <AdminRoute>
              <Users />
            </AdminRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/activity-log"
        element={
          <ProtectedRoute>
            <AdminRoute>
              <ActivityLog />
            </AdminRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/regions"
        element={
          <ProtectedRoute>
            <AdminRoute>
              <Regions />
            </AdminRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/payment/:id"
        element={
          <ProtectedRoute>
            <UiState />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <NotFound />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default PageRouter;
