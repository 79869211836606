// External elements
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Custom elements
import Button from '@/components/Button';

// CSS
import './WaitingForBuyer.scss';

const WaitingForBuyer = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="waiting-for-buyer">
      <img src="/step-4/hourglass.png" />

      <h1>{t('sellerstep1:title')}</h1>

      <div className="text-container">
        <p>{t('sellerstep1:paragraph1')}</p>
        <p>{t('sellerstep1:paragraph2')}</p>
      </div>

      <Button
        action={(): void => navigate('/')}
        primary={false}
        style={{ width: '100%', marginTop: 50 }}
      >
        {t('paymentpaused:button')}
      </Button>
    </div>
  );
};

export default WaitingForBuyer;
