import { ReactElement, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createTransaction, fetchAdInfo, updateTransactionStatus } from '@/api';
import { useReduxDispatch } from '@/store/hooks';
import { incrementStep } from '@/store/stepper';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TransactionStatus } from '@/views/Buyer';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

interface DirectPayWindow extends Window {
  DirectPayIpg?: {
    Init: new (config: {
      signature: string;
      dataString: string;
      stage: string;
      container: string;
    }) => {
      doInAppCheckout: () => Promise<any>;
      doInContainerCheckout: () => Promise<any>;
    };
  };
}

declare const window: DirectPayWindow;

interface TransactionResponse {
  signature: string;
  encode_payload: string;
}

const DirectPay = (): ReactElement => {
  const stage = process.env.REACT_APP_DIRECT_PAY_ENV || 'DEV';
  const containerId = 'card_container';

  const dispatch = useReduxDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState<{
    status: number;
    data: { code: string; message?: string; title?: string };
  } | null>(null);
  const [initializing, setInitializing] = useState<boolean>(false);
  const hasMounted = useRef(false);

  const { data } = useQuery({
    queryKey: ['adInfo', id],
    queryFn: () => fetchAdInfo(id!),
    enabled: error?.status === 400 && error?.data.code === 'duplicateOrderId',
    refetchInterval: 1000,
  });

  const mutation = useMutation<TransactionResponse, Error, string>({
    mutationFn: (adId: string) => createTransaction(adId),
    onMutate: () => {
      setInitializing(true);
    },
    onSuccess: (response) => {
      const initializeDirectPay = (
        signature: string,
        dataString: string,
        stage: string,
        containerId: string
      ): void => {
        if (window.DirectPayIpg && window.DirectPayIpg.Init) {
          const dp = new window.DirectPayIpg.Init({
            signature,
            dataString,
            stage,
            container: containerId,
          });

          setInitializing(false);

          dp.doInContainerCheckout()
            .then((): void => {
              dispatch((): any => incrementStep());
            })
            .catch(
              (error: {
                status: number;
                data: { code: string; message?: string; title?: string };
              }): void => {
                setError(error);
              }
            );
        } else {
          setError({
            status: 400,
            data: {
              code: 'notAvailableOnWindow',
              message: 'DirectPayIpg is not available on window object',
            },
          });
        }
      };

      const loadDirectPayScript = (): void => {
        const script = document.createElement('script');
        script.src = 'https://cdn.directpay.lk/v3/directpayipg.min.js';
        script.async = true;
        script.onload = (): void => {
          initializeDirectPay(
            response.signature,
            response.encode_payload,
            stage,
            containerId
          );
        };
        document.body.appendChild(script);
      };

      if (window.DirectPayIpg && window.DirectPayIpg.Init) {
        initializeDirectPay(
          response.signature,
          response.encode_payload,
          stage,
          containerId
        );
      } else {
        loadDirectPayScript();
      }

      return (): void => {
        const scriptElement = document.querySelector(
          'script[src="https://cdn.directpay.lk/v3/directpayipg.min.js"]'
        );
        if (scriptElement) {
          scriptElement.remove();
        }
      };
    },
  });

  const mutationStatus = useMutation<
    void,
    Error,
    { status: string; guid: string }
  >({
    mutationKey: ['statusUpdate', id],
    mutationFn: ({ status, guid }: { status: string; guid: string }) =>
      updateTransactionStatus(status, guid),
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      window.location.reload();
    },
  });

  useEffect(() => {
    if (id && !hasMounted.current) {
      hasMounted.current = true;
      mutation.mutate(id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      if (
        data.status !== TransactionStatus.InitializedPayment &&
        data.status !== TransactionStatus.Created
      ) {
        window.location.reload();
      }
    }
  }, [data]);

  useEffect(() => {
    const status = searchParams.get('status');
    const desc = searchParams.get('desc');

    if (
      (status === 'SUCCESS' && desc === 'Approved' && !!id) ||
      (error?.data?.code === 'duplicateOrderId' && !!id)
    ) {
      mutationStatus.mutate({ status: 'processing-payment', guid: id });
    }
  }, [searchParams]);

  return (
    <div>
      {initializing && (
        <Box
          display="flex"
          flexDirection="column"
          rowGap={2}
          alignItems="center"
        >
          <CircularProgress />
          <Typography variant="body1">Loading...</Typography>
        </Box>
      )}
      <div id="card_container"></div>
    </div>
  );
};

export default DirectPay;
