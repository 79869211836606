// External elements
import { Box, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ReactElement, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Custom elements
import { fetchAdInfo, updateTransactionRegion } from '@/api';
import { DeliveryForm } from '@/types';
import Button from './Button';
import Icon from './Icon';
import { useReduxDispatch } from '@/store/hooks';
import { incrementStep } from '@/store/stepper';

const DeliveryAddress = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const {
    data: transaction,
    isLoading: isLoadingTransaction,
    isError: isErrorTransaction,
  } = useQuery({
    queryKey: ['adInfo', id],
    queryFn: () => fetchAdInfo(id!),
    enabled: !!id,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<DeliveryForm>();

  const updateDeliveryAddressMutation = useMutation({
    mutationFn: ({ data, guid }: { data: DeliveryForm; guid: string }) =>
      updateTransactionRegion(data, guid),
    onError: (error) => {
      enqueueSnackbar(`${t('deliveryAddress:error')}: ${error.message}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t('deliveryAddress:addedSuccess'), {
        variant: 'success',
        autoHideDuration: 1000,
      });
      dispatch(incrementStep());
    },
  });

  const onSubmit: SubmitHandler<DeliveryForm> = (data) => {
    if (!!id && data) {
      updateDeliveryAddressMutation.mutate({ data: data, guid: id });
    } else {
      enqueueSnackbar(t('deliveryAddress:unknownError'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    if (transaction?.address) {
      setValue('streetAddress', transaction.address.streetAddress);
      setValue('city', transaction.address.city);
      setValue('postCode', transaction.address.postCode);
    }
  }, [transaction]);

  return (
    <>
      {isLoadingTransaction && <h1>Loading...</h1>}

      {!isLoadingTransaction && !isErrorTransaction && (
        <Box display="flex" flexDirection="column">
          <img
            src="/step-5/box.png"
            style={{ maxWidth: 130, width: '100%', alignSelf: 'center' }}
          />
          <Box display="flex" flexDirection="column" alignItems="center">
            <h1>{t('deliveryAddress:deliveryAddress')}</h1>
            <p>{t('deliveryAddress:paragraph1')}</p>
          </Box>

          <Box display="flex" flexDirection="column">
            <Box display="flex" width="100%" columnGap={4}>
              <Box display="flex" flexDirection="column" width="100%">
                <h4>{t('deliveryAddress:postCode')}</h4>

                <Controller
                  control={control}
                  name="postCode"
                  rules={{
                    required: t('deliveryAddress:postCodeRequired'),
                  }}
                  render={({ field: { onChange, value } }): JSX.Element => (
                    <TextField
                      value={value}
                      label={`${t('deliveryAddress:postCode')}..`}
                      defaultValue={transaction?.address?.postCode}
                      fullWidth
                      onChange={(e): void => onChange(e.target.value)}
                      inputProps={{ type: 'number' }}
                      size="small"
                    />
                  )}
                />
                {errors.postCode && (
                  <Typography color="error" variant="body2">
                    {errors.postCode.message as string}
                  </Typography>
                )}
              </Box>

              <Box display="flex" flexDirection="column" width="100%">
                <h4>{t('deliveryAddress:city')}</h4>

                <Controller
                  control={control}
                  name="city"
                  rules={{
                    required: t('deliveryAddress:cityRequired'),
                  }}
                  render={({ field: { onChange, value } }): JSX.Element => (
                    <TextField
                      value={value}
                      label={`${t('deliveryAddress:city')}...`}
                      defaultValue={transaction?.address?.city}
                      fullWidth
                      onChange={(e): void => onChange(e.target.value)}
                      size="small"
                    />
                  )}
                />
                {errors.city && (
                  <Typography color="error" variant="body2">
                    {errors.city.message as string}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box display="flex" width="100%" columnGap={4}>
              <Box display="flex" flexDirection="column" width="100%">
                <h4>{t('deliveryAddress:streetAddress')}</h4>

                <Controller
                  control={control}
                  name="streetAddress"
                  rules={{
                    required: t('deliveryAddress:streetAddressRequired'),
                  }}
                  render={({ field: { onChange, value } }): JSX.Element => (
                    <TextField
                      value={value}
                      label={`${t('deliveryAddress:streetAddress')}...`}
                      defaultValue={transaction?.address?.streetAddress}
                      fullWidth
                      onChange={(e): void => onChange(e.target.value)}
                      size="small"
                    />
                  )}
                />
                {errors.streetAddress && (
                  <Typography color="error" variant="body2">
                    {errors.streetAddress.message as string}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box mt={4} mb={2} alignSelf="center">
              <p>{t('deliveryAddress:regionParagraph')}</p>
            </Box>

            <Button
              primary={true}
              style={{ marginTop: 50 }}
              disabled={updateDeliveryAddressMutation.isPending}
              action={handleSubmit(onSubmit)}
            >
              {t('deliveryAddress:next')}{' '}
              <Icon name="arrow-right" style="fas" />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DeliveryAddress;
