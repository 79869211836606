// External elements
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Custom elements
import Button from '@/components/Button';

// CSS
import './WaitingForReceival.scss';

const WaitingForReceival = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="waiting-for-receival">
      <img src="/step-4/hourglass.png" />

      <h1>{t('sellerstep3:title')}</h1>

      <div className="text-container">
        <p>
          <b>{t('sellerstep3:subtitleBold')}</b>{' '}
          {t('sellerstep3:subtitleNormal')}
        </p>
      </div>

      <Button
        action={(): void => navigate('/')}
        primary={false}
        style={{ width: '100%', marginTop: 50 }}
      >
        {t('paymentpaused:button')}
      </Button>
    </div>
  );
};

export default WaitingForReceival;
