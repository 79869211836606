// External elements
import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

// Custom elements
import { useReduxSelector } from '@/store/hooks';
import { StepperStep, StepperSteps } from './StepperSteps';

// CSS
import './Stepper.scss';

interface StepperProps {
  children: ReactNode;
}

const Stepper = ({ children }: StepperProps): ReactElement => {
  const className = classNames({
    stepper: true,
  });
  const steps = useReduxSelector((state) => state.stepper.steps);
  const { currentStep } = useReduxSelector((state) => state.stepper);

  const hiddenHeaderSteps = [0, 2];

  return (
    <div>
      <div className={className}>
        {steps?.map((step, index) => {
          if (!hiddenHeaderSteps.includes(currentStep)) {
            return (
              <li
                className={`item ${index <= currentStep ? 'completed' : ''}`}
                key={step.id}
              />
            );
          }
        })}
      </div>

      <div>{children}</div>
    </div>
  );
};

Stepper.Step = StepperStep;
Stepper.Steps = StepperSteps;

export default Stepper;
