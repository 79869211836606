// External elements
import { ReactElement, forwardRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

// Custom elements
import Button from '@/components/Button';
import { TransactionStatus } from '@/views/Buyer';
import { updateTransactionStatus } from '@/api';

// CSS
import './ReceiveProduct.scss';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReceiveProduct = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean | string>(false);

  const mutation = useMutation({
    mutationFn: ({ status, guid }: { status: string; guid: string }) =>
      updateTransactionStatus(status, guid),
    onSuccess: () => {
      if (open === 'problem') {
        enqueueSnackbar(t('step5:supportText'));
      }
      setOpen(false);
      location.reload();
    },
  });

  return (
    <div className="receive-product">
      <img src="/step-5/box.png" />

      <h1>{t('step5:title')}</h1>

      <p>{t('step5:firstLine')}</p>

      <p>{t('step5:secondLine')}</p>

      <p>
        <b>{t('step5:thirdLine')}</b>
        <ul>
          <li>{t('step5:bulletPoint')}</li>
        </ul>
      </p>

      <Dialog
        open={!!open}
        onClose={(): void => setOpen(false)}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle sx={{ textAlign: 'center', position: 'relative' }}>
          <h1>
            {open === 'receive' ? t('step5:button1') : t('step5:button2')}
          </h1>

          <IconButton
            aria-label="close"
            onClick={(): void => setOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ px: 4, pt: 2, pb: 4 }}>
            <p>{open === 'receive' ? t('step5:happy') : t('step5:sorry')}</p>
            {open === 'receive' && <p>{t('step5:rightButton')}</p>}
            <p>
              {open === 'receive'
                ? t('step5:confirmReceive')
                : t('step5:problemConfirm')}
            </p>
          </DialogContentText>

          <div className="button-container">
            <Button
              primary={open === 'receive'}
              style={{ width: '100%' }}
              loading={mutation.isPending}
              action={(): any => {
                if (open === 'receive' && id) {
                  mutation.mutate({
                    status: TransactionStatus.Received,
                    guid: id,
                  });
                } else if (open === 'problem' && id) {
                  mutation.mutate({
                    status: TransactionStatus.BuyerProblem,
                    guid: id,
                  });
                } else {
                  enqueueSnackbar(t('step5:errorUpdate'), {
                    variant: 'error',
                    autoHideDuration: 2000,
                  });
                }
              }}
            >
              {open === 'receive'
                ? t('step5:button1Modal')
                : t('step5:button2Modal')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <div className="buttons-container">
        <Button primary action={(): any => setOpen('receive')}>
          {t('step5:button1')}
        </Button>
        <Button primary={false} action={(): any => setOpen('problem')}>
          {t('step5:button2')}
        </Button>
      </div>
    </div>
  );
};

export default ReceiveProduct;
