import { ReactElement } from 'react';
import Loader, { LoaderStyles } from '@/components/Loader';
import './Loading.scss';

function Loading(): ReactElement {
  return (
    <div className="loading">
      <Loader color="#000" style={LoaderStyles.ROTATE_CIRCLE} />
    </div>
  );
}

export default Loading;
