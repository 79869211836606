// External elements
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Custom elements
import IntroCard from '@/components/IntroCard';
import TermsCheckbox from '@/components/TermsCheckbox';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { incrementStep } from '@/store/stepper';
import { useReduxDispatch } from '@/store/hooks';

// CSS
import './HowItWorks.scss';

const HowItWorks = (): ReactElement => {
  const [termsAgreed, setTermsAgreed] = useState(false);

  const { t } = useTranslation();

  const dispatch = useReduxDispatch();

  const acceptTermsHandler = (): void => {
    setTermsAgreed(!termsAgreed);
  };

  return (
    <div className="how-it-works">
      <h1>{t('intro:title')}</h1>
      {[1, 2, 3, 4].map((step) => (
        <IntroCard
          key={step}
          image={`/intro/img/buyer-how-it-works-${step}.svg`}
          head={t(`intro:cardHead${step}`)}
          body={t(`intro:cardTitle${step}`)}
        />
      ))}

      <div className="terms-container">
        <TermsCheckbox
          content={t('intro:terms')}
          value={termsAgreed}
          onChange={acceptTermsHandler}
        />

        <div className="button-container">
          <Button
            disabled={!termsAgreed}
            primary={true}
            action={(): any => termsAgreed && dispatch(incrementStep())}
          >
            {t('intro:getStarted')}
            <Icon name="arrow-right" style="fas" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
