import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { store } from '@/store/';
import '@/index.scss';
import App from '@/App';
import './language/i18n.ts';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '@/store/';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <Provider store={store}>
            <Router>
              <App />
            </Router>
          </Provider>
        </SnackbarProvider>
      </QueryClientProvider>
    </PersistGate>
  </React.StrictMode>
);
